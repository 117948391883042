.templates-main {
  width: 100%;
  min-width: 510px;
  padding: 24px;
  margin-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.templates-description {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.templates-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
}

.templates-add-actions {
  margin-bottom: 16px;
}

.templates-button {
  padding: 12px 25px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.templates-button:not(.templates-button-simple):before {
  width: 10px;
  height: 10px;
  position: relative;
  margin-right: 7px;
  content: url('./../../../images/icons/Plus.svg');
}

.templates-button-simple {
  margin-bottom: 32px;
}

.templates-button:hover {
  background-color: #9D1B1F;
}

.templates-button:active {
  background-color: #B72025;
}

.templates-button:disabled {
  background-color: #EFB7B6;
}

.templates-add-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.templates-add-subtitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.templates-select {
  position: relative;
  z-index: 2;
}

.table-th {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
}

.email-template-select-multi {
  width: fit-content;
  max-width: 320px;
  margin-bottom: 12px;
  position: relative;
  z-index: 3;
}

.email-template-select-multi::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 7px;
  left: 13px;
  content: url('../../../images/icons/Email template.svg');
}

.multiselect {
  margin-bottom: 12px;
  width: 320px;
  min-height: 40px;
}

.audit-desc {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.template-table {
  margin-bottom: 25px;
  width: 100%;
}

.template-clickable-row {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  transition: background-color .2s;
  cursor: pointer;
}

.template-not-clickable {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}


.template-clickable-row:hover {
  background-color: #FBEDED;
}

.template-selected,
.template-selected:hover {
  background-color: #ffdede;
}

.template-tr {
  display: grid;
  grid-template-columns: 2fr 5fr 1fr;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  padding: 9px 0 9px 12px;
  width: 100%;
  min-height: 40px;
  text-align: left;
}

.template-tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  text-align: left;
}

.template-tr th:last-of-type {
  margin: 0 auto;
}

.template-tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.td-email {
  color: #5D5B5B;
}

.template-delete-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 54px;
  gap: 4px;
}

.template-button-delete {
  border: none;
  background: none;
  padding: 0;
  transition: all .2s;
}

.template-button-delete:disabled {
  opacity: 0.4;
}

.template-button-delete:not(:disabled) {
  opacity: 0.8;
  cursor: pointer;
}

.template-button-delete:not(:disabled):hover {
  opacity: 1;
  transform: scale(1.1);
}

.template-button-delete img {
  position: relative;
  overflow: hidden;
  width: 24px;
  height: 24px;
}

.templates-label::before {
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
  margin-right: 5px;
  content: url('../../../images/icons/Email template Small.svg');
}

.caption {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.template-not-clickable button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.template-not-clickable button:hover {
  color: #9D1B1F
}

.template-not-clickable button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.template-not-clickable button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.templates-modal {
  padding: 16px;
  overflow: scroll;
  height: 80vh;
}
