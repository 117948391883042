.exclusions-main {
  margin: 32px 40px 40px 40px;
}

.exclusions-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.exclusions-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 8px;
}

.exclusions-item {
  display: block;
  padding: 0 8px 8px 8px;
  min-width: fit-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  text-decoration: none;

  transition: color 0.3s;
}

.exclusions-item:not(.exclusions-item--inactive):hover {
  color: #9D1B1F;
}

.exclusions-item--active {
  position: relative;
  color: #9D1B1F;
}

.exclusions-item--active:after {
  position: absolute;
  background-color: #9D1B1F;
  content: "";
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.exclusions-item--inactive {
  opacity: 0.5;
  cursor: not-allowed;
}
