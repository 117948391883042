.edu-table {
  margin-bottom: 25px;
  border-style: solid;
  /* border-color: #E6E6E6; */
  border-color: transparent;
  border-width: 1px 1px 1px 1px;
  border-collapse: collapse;
  margin: auto;
  width: 100%;
}

.templates-arrows-container {
  display: flex;
  gap: 10px;
  margin-right: 24px;
}

.edu-clickable-row {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  transition: background-color .2s;
  cursor: pointer;
}

.edu-clickable-row:hover {
  background-color: #FBEDED;
}

.edu-selected,
.edu-selected:hover {
  background-color: #ffdede;
}

.edu-tr {
  display: grid;
  grid-template-columns: 2fr;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  padding: 9px 0 9px 12px;
  width: 100%;
  min-height: 40px;
  text-align: left;
}

.edu-tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  text-align: left;
}

.edu-tr th:last-of-type {
  margin: 0 auto;
}

.edu-tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  border-color: transparent;
}

.education-card-phish {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%
}

.education-card-phish-tables {
  display: flex;
  min-width: fit-content;
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%
}

.scroll-container {
  max-height: 100vh;
  overflow: scroll;
  width: 30%;
}

.equal-container-size {
  min-height: 100%;
}

.scroll-container::-webkit-scrollbar {
  width: 10px
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #9D1B1F;
  border-radius: 5px
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #B72025
}

.preview-container {
  width: 70%;
  border-style: solid;
  border-color: #E6E6E6;
  border-width: 1px 1px 1px 1px;
  border-collapse: collapse;
  min-height: 100vh;
}

.templates-button-container {
  display: flex;
  justify-content: space-between;
  margin: 24px;
  gap: 10px;
}

.template-input-edit {
  width: 30%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  padding: 9px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  transition: all 0.1s linear;
  align-self: center;
}

.template-input-edit:focus {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.template-input-edit:hover {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.arrow-button {
  background: none !important;
  box-shadow: none !important;
  border: none;
  padding: 0;
  cursor: pointer;
}

.arrow-button:hover:enabled {
  transform: scale(1.2);
  opacity: 0.9
}

.arrow-button:disabled {
  opacity: 0.6;
  cursor: auto;
}

.education-card-container {
  display: flex;
  justify-content: space-between;
}

.templates-title {
  margin-bottom: 8px;
  width: fit-content;
}

.main-details-edu {
  display: flex;
}

.edu-button-container {
  display: flex;
  gap: 10px;
}

.edu-template-container {
  margin: auto;
}

.edu-html {
  padding: 16px;
}

.edu-modal {
  padding: 24px;
  width: 35vw;
}

.edu-modal-title {
  margin-bottom: 12px;
  font-size: 20px;
  text-align: left;
  color: #343232
}

.edu-card-title {
  margin-bottom: 8px;
  text-align: center;
  color: #343232;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.edu-card-content {
  width: 100%;
  padding: 0 8px;
  position: relative;
  margin-bottom: 12px;
}

.edu-select {
  width: 100%;
  margin-bottom: 12px;
}

.edu-texarea {
  width: 100%;
  min-height: 300px;
  resize: vertical;
}

.edu-modal-buttons-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.id-container {
  align-self: center;
  color: #767676;
}

.email-segment-select {
  width: 30%;
  align-self: center;
 }

