.edit-card {
  display: inline-block;
}

.edit-form {
  width: 100%;
}

.edit-description {
  margin-bottom: 2px;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.edit-description::before {
  content: '!!!';
  color: #DE2C37;
  margin-right: 5px;
}

.input-field-or {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 22px;
}

.input-field-or span {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-field img {
  display: inline-block;
  transform: translateY(2px);
  margin-left: 3px;
  cursor: pointer;
}

.input-field a {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-edit {
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  padding: 9px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  transition: all 0.1s linear;
}

.input-edit::placeholder {
  font-size: 0.9em;
  color: #A4A3A3;
}

.input-edit:hover:not(:disabled) {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.input-edit:focus:not(:disabled) {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.input-edit:disabled {
  background: #F3F3F3;
  color: #A4A3A3;
}

.input-edit-last {
  margin-bottom: 36px;
}

.input-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-label-required:after {
  color: #DE2C37;
  content: '*';
  margin-left: 2px;
}

.input-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.edit-list {
  margin-bottom: 4px;
  display: grid;
  grid-template-columns: minmax(244px, 1fr) minmax(244px, 1fr) minmax(244px, 1fr) minmax(244px, 1fr);
  gap: 32px;
}

.edit-list-groups {
  display: grid;
  grid-template-columns: 10fr 1fr 10fr;
  gap: 10px;
}

.edit-list-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.button-submit {
  padding: 12px 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button-submit:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.button-submit:hover {
  background-color: #9D1B1F;
}

.button-submit:active {
  background-color: #B72025;
}

.button-submit img {
  display: inline;
  margin-right: 6px;
}

.details-help {
  margin: 0 0 8px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.details-help a {
  position: relative;
  color: #B72025;
  font-weight: 600;
  text-decoration: none;
}

.details-help a::after {
  position: absolute;
  background-color: #B72025;
  content: "";
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}
.multiselect-azure {
  width: 100%;
  margin-bottom: 16px;
}

.multiselect-azure::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 13px;
  content: url('../../images/icons/world-svgrepo-com.svg');
}

.filter-checkbox-sync {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
  top: 12px,
}

.filter-checkbox-sync span:nth-of-type(2) {
  position: absolute;
  left: 24px;
}

.filter-checkbox-sync input {
  width: 14px;
  height: 14px;
  accent-color: #B72025;
  cursor: pointer;
}

.filter-checkbox-sync label {
  display: flex;
  flex-direction: row;
}

.input-label {
  color: var(--neutral-90, #343232);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.28px;
  padding-bottom: 4px;
}

.filter-checkbox-label {
  display: flex;
  align-self: start;
  margin-right: 6px;
  transform: translate(0, 1px);
}
