.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;

  width: 500px;

  background: #FFFFFF;
  border-radius: 4px;
}

.title {
  margin-bottom: 32px;
  text-align: center;
}

.card-title {
  margin-bottom: 8px;
  text-align: center;
  color: #B72025;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.card-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.card-content {
  position: relative;
  margin-bottom: 12px;
}

.input-field {
  margin-bottom: 12px;
  position: relative;
  width: 100%;
}

.input-field label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: 0.02em;
  color: #343232;
}

.input {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 16px;

  width: 404px !important;
  height: 48px !important;

  border: 1px solid #D6D6D6 !important;
  border-radius: 4px;
}

.input:disabled {
  cursor: not-allowed;
}

.input-field:has(.input[name="email"]) img {
  position: absolute;
  width: 14px;
  height: 10;
  transform: translate(11px, 43px);
  z-index: 99;
}

.input-field:has(.input[name="company"]) img {
  position: absolute;
  transform: translate(10px, 40px);
  z-index: 1;
}

.input-field:has(.input[name="password"]) img,
.input-field:has(.input[name="oldPassword"]) img,
.input-field:has(.input[name="confirmPassword"]) img {
  position: absolute;
  width: 14px;
  height: 10;
  transform: translate(11px, 45px);
  z-index: 1;
}

.eye {
  position: absolute;
  width: 14px;
  height: 10;
  transform: translate(370px, 41px) !important;
  z-index: 99;
  cursor: pointer;
  opacity: 0.7;
}

.eye:hover {
  opacity: 1;
}

.input-dropdown {
  border: 1px solid #D6D6D6 !important;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12) !important;
  border-radius: 4px !important;
  width: 404px !important;
}

.flag-dropdown {
  background-color: #fff !important;
  border-right: none !important;
}

.selected-flag {
  padding-left: 15px !important;
}

.input::placeholder {
  font-size: 0.9em;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.input:not(:disabled):hover {
  box-shadow: 0 0 3px 0.5px #e7e6e6;
}

.input:focus {
  box-shadow: 0 0 3px 2px #e7e6e6;
  outline: none;
  border-color: #d3d3d3;
}

.err {
  right: 0;
  color: #ed0000;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.02em;
}

.err-input {
  border: 1px solid #ed0000
}

.err-input:hover {
  box-shadow: 0 0 3px 0.5px #ed00004f;
}

.err-input:focus {
  box-shadow: 0 0 3px 0.5px #ed000072;
  outline: none;
  border-color: #ed0000;
}

.same-email {
  margin-bottom: 16px;
}

.checkbox-action {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
}

.checkbox-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.checkbox-label:not(.checkbox-label-loading):hover {
  cursor: pointer;
}

.checkbox-action span:nth-of-type(2) {
  position: absolute;
  left: 24px;
}

.checkbox-action input {
  display: none;
}

.checkbox-action label {
  display: flex;
  flex-direction: row;
}

.checkbox-span {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #C2C1C1;
  transition: all 0.3s;
}

.checkbox-span:not(.checkbox-span-loading) {
  cursor: pointer;
}

.checkbox-action input:checked ~ .checkbox-span {
  rotate: 45deg;
  translate: 4px -2px;
  width: 8px;
  border-color: #B72025;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 1px
}

.forgot-password {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #343232;
  transition: color 0.3s;
}

.forgot-password:hover {
  color: #B72025;
}

.card-action {
  margin-bottom: 32px;
}
.card-action-auth {
  margin-bottom: 8px;
}

.card-action-auth-last {
  margin-bottom: 32px;
}

.card-action-or {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.card-action:has(.button-reverse) {
  margin-bottom: 0;
}
.card-action button {
  width: 404px;
  height: 48px;
  border: 2px solid transparent;
  background-color: #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;

  transition: background-color .3s;
}

.card-action button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.card-action button:not(:disabled):hover {
  background-color: #9D1B1F;
}

.card-action button:not(:disabled):active {
  background-color: #B72025;
}

.card-action button.button-reverse {
  width: 320px;
  height: 48px;
  border: 2px solid #B72025;
  background: #FFFFFF;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #B72025;
  line-height: 16px;
  letter-spacing: 0.02em;

  transition: all .3s;
}

.card-action button.button-reverse:hover {
  background: #B72025;
  color: #FFFFFF;
}

.button-navlink {
  text-decoration: none;
  color: inherit;
}

.back-register {
  width: 100%;
  text-align: left;
}

.back-register h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #343232;
  margin-bottom: 12px;
}

.back-register-button {
  position: relative;
  padding: 0;
  margin-bottom: 24px;
  border: none;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02e;
  color: #B72025;
  transition: all .1s linear;
}

.back-register-button:not(:disabled) {
  cursor: pointer;
}

.back-register-button::before {
  content: url('../images/icons/Chevron-left.svg');
  position: relative;
  margin-right: 2px;
  top: 2px;
}

.back-register-button:not(:disabled):hover {
  transform: translateX(-3px);
}

.next-register-button:disabled,
.last-register-button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.next-register-button::after {
  content: url('../images/icons/Chevron-right-white.svg');
  position: relative;
  margin-left: 4px;
  top: 2px;
}

.signup-action {
  transition: color .3s, transform .2s;
}

.signup-action img {
  display: inline;
  margin-right: 5px;
  transform: translateY(2px);
}

.signup-action span:first-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.signup-action span:last-child,
.signin-navlink,
.signin-navlink-auth {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  transition: color .3s;
  cursor: pointer;
}

.signup-action span:last-child:hover,
.signin-navlink-auth:hover {
  cursor: pointer;
  color: #B72025;
}

.signup-action:has(img):hover {
  transform: translateX(-3px);
  cursor: pointer;
  color: #B72025;
}

.edit-list-change {
  margin-bottom: 16px;
}

.change-password-card {
  padding: 40px;
}

.country-list {
  height: 155px;
}

.google-button {
  margin-bottom: 32px;
  width: 100% !important;
  height: 48px !important;
  box-shadow: none !important;
  border: 1.5px solid #D6D6D6 !important;
  border-radius: 4px !important;
  font-family: inherit !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B !important;
  transition: all .1s ease-in-out !important;
}

.google-button:hover {
  border-color: #B72025 !important;
  box-shadow: none!important;
  transition: none !important;
}

.google-button > div,
.google-button > div > svg {
  position: absolute !important;
  width: 42px !important;
  height: 42px !important;
}

.sso-button {
  position: relative;
  width: 100%;
  padding: 16px;
  margin-bottom: 32px;
  background-color: #fff;
  border: 1.5px solid #D6D6D6 !important;
  border-radius: 4px !important;
  font-family: inherit !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B !important;
  transition: all .1s ease-in-out !important;
  cursor: pointer;
}

.sso-button:hover {
  border-color: #B72025 !important;
}

.sso-button::before {
  position: absolute;
  content: url('../images/icons/sso2.png');
  top: 8px;
  left: 12px;
}

.google-button::before {
  position: absolute;
  content: url('../images/icons/Google (colors) medium.svg');
  top: 13px;
  left: 12px;
}

.keycloak-button::before {
  position: absolute;
  content: url('../images/icons/keycloak-svgrepo-com.svg');
  top: 12px;
  left: 12px;
}

.form-loading,
.back-register-button-loading,
.input-field-loading,
.input-loading,
.input-label-loading,
.checkbox-loading,
.checkbox-label-loading {
  cursor: wait;
}
