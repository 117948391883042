.email-container {
  height: 24px;
  border: 1px solid #A4A3A3;
  padding: 4px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.users-filter-label::before {
  width: 11px;
  height: 8px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  content: url('../../images/icons/Envelope Small.svg');
}

.users-list-tenant-label::before {
  position: relative;
  top: 5px;
  left: 0;
  margin-right: 5px;
  content: url('../../images/icons/noun-tenant.svg');
}

.non-engagement-label::before {
  position: relative;
  top: 4px;
  left: 0;
  margin-right: 7px;
  content: url('../../images/icons/uneducated-color.svg');
}

.user-filters-select {
  position: relative;
  z-index: 4;
}

.user-filters-select-tenant::before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 31px;
  left: 12px;
  content: url('../../images/icons/Layer Group.svg');
}

.user-filters-select-sync::before {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 33px;
  left: 13px;
  content: url('../../images/icons/Sync.svg');
}

.all-filters-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.filter-users-select {
  width: fit-content;
  position: relative;
  z-index: 3;
}

.filter-users-select::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 32px;
  left: 13px;
  content: url('../../images/icons/Search.svg');
}

.users-filter-selected-bar {
  display: flex;
  margin: 8px 8px 8px 0;
  align-items: center;
}

.users-filter-email-button-active::after {
  width: 8px;
  max-height: 8px;
  position: relative;
  margin-left: 4px;
  top: 3px;
  content: url('./../../images/icons/expand-collapse.svg');
}

.users-filter-email-button {
  display: flex;
  align-items: center;
  border: 1px solid #A4A3A3;
  height: 24px;
  border-radius: 4px;
  background-color: #F3F3F3;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  margin-right: 8px;
  cursor: pointer;
  transition: border-color .1s ease-in-out;
}

.users-filter-email-button:hover {
  border-color: #5D5B5B;
}

.users-filter-email-button::after {
  width: 8px;
  height: 8px;
  position: relative;
  margin: 0 6px 0 4px;
  top: -4px;
  content: url('./../../images/icons/sort.svg');
}

.users-filter-email-button-active::after {
  width: 8px;
  max-height: 8px;
  position: relative;
  margin: 0 6px 0 4px;
  top: -4px;
  content: url('./../../images/icons/expand-collapse.svg');
}

.users-filter-email-button-active {
  display: flex;
  align-items: center;
  border: 1px solid #A4A3A3;
  height: 24px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  margin-right: 8px;
  background-color: #5D5B5B;
}

.users-filter-secondary-button {
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #B72025;
}

.users-filter-secondary-button:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
}

.users-filter-secondary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.users-filter-emails-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
}

.users-filter-email-container {
  height: 24px;
  border: 1px solid #A4A3A3;
  padding: 4px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  display: flex;
  align-items: center;
  gap: 6px;
}

.users-filter-search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.users-filter-search-container-empty {
  margin-bottom: 8px;
}
