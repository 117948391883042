.mapping-main {
  min-width: 510px;
  width: 100%;
  padding: 24px;
  margin-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.mapping-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}

.mapping-description {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.mapping-button {
  padding: 12px 25px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mapping-button-simple {
  margin-bottom: 0;
}

.mapping-button:not(.mapping-button-simple):before {
  width: 10px;
  height: 10px;
  position: relative;
  margin-right: 7px;
  content: url('./../../images/icons/Plus.svg');
}

.mapping-button:hover {
  background-color: #9D1B1F;
}

.mapping-button:active {
  background-color: #B72025;
}

.mapping-button:disabled {
  background-color: #EFB7B6
}

.mapping-select {
  position: relative;
  z-index: 2;
}

.mapping-select::before {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 10px;
  left: 13px;
  z-index: 2;
  content: url('../../images/icons/Groups.svg')
}

.mapping-users-table {
  margin-bottom: 25px;
  width: 100%
}

.mapping-users-not-clickable {
  background-color: #F3F3F3;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s
}

.mapping-selected,
.mapping-selected:hover {
  background-color: #ffdede;
}

.mapping-users-tr {
  display: grid;
  grid-template-columns: 3fr 3fr 4fr 5fr 2fr;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  align-self: flex-start;
  padding: 9px 0 9px 12px;
  width: 100%;
  min-height: 40px;
  text-align: left;
}

.mapping-users-tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  text-align: left;
}

.mapping-users-tr th:last-of-type {
  margin: 0;
}

.mapping-users-tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.mapping-td-email {
  color: #5D5B5B
}

.mapping-users-not-clickable button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.mapping-users-not-clickable button:hover {
  color: #9D1B1F
}

.mapping-users-not-clickable button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.mapping-users-not-clickable button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px
}

.mapping-td-edit {
  display: flex;
  gap: 8px
}

.mapping-td-edit button {
  display: none;
}

.mapping-th-edit::after {
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  right: 3px;
  content: url('../../images/icons/Edit.svg');
}

.mapping-td-edit .map-edit::after {
  display: block;
  height: 20px;
  width: 20px;
  content: url('../../images/icons/Pen.svg');
  transition: all .1s ease-in-out;
}

.mapping-td-edit .map-edit:hover:after {
  display: block;
  height: 20px;
  width: 20px;
  content: url('../../images/icons/Pen.svg');
  transform: scale(1.1);
}

.mapping-td-edit .map-delete::after {
  display: block;
  height: 20px;
  width: 20px;
  content: url('../../images/icons/Trash.svg');
  transition: all .1s ease-in-out;
}

.mapping-td-edit .map-delete:hover:after {
  display: block;
  height: 20px;
  width: 20px;
  content: url('../../images/icons/Trash.svg');
  transform: scale(1.1);
}

.new-mapping-input {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: flex-end;
  gap: 20px;
}

.new-mapping-select {
  position: relative;
  z-index: 4;
  width: 100%;

}

.new-mapping-select-label {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  width: 100%;
}

/* .mapping-select-group::before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 32px;
  left: 12px;
  content: url('../../images/icons/Groups.svg');
} */

.mapping-td-sync {
  display: flex;
  align-items: center;
  gap: 4px;
}

.groups-map-tenant-label::before {
  position: relative;
  top: 5px;
  left: 0;
  margin-right: 5px;
  content: url('../../images/icons/noun-tenant.svg');
}

.groups-map-table-label::before {
  width: 11px;
  height: 8px;
  position: relative;
  top: 3px;
  margin-right: 5px;
  content: "";
}

.groups-map-select-sync::before {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 33px;
  left: 13px;
  content: url('../../images/icons/Sync.svg');
}

.groups-map-select-tenant::before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 32px;
  left: 12px;
  content: url('../../images/icons/Layer Group.svg');
}

.mapping-buttons-container {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.mapping-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.modal-form {
  padding: 24px;
  max-height: 80vh;
  /* overflow: scroll; */
}

.modal-title {
  margin-bottom: 12px;
  font-size: 20px;
  text-align: left;
  color: #343232;
}

.modal-content {
  width: 100%;
  padding: 0 8px;
}

.modal-select {
  width: 100%;
  margin-bottom: 12px;
}

.mapping-select-label {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.modal-button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.mapping-sync {
  display: flex;
  align-items: center;
  gap: 4px;
}

