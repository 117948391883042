.main-title {
  margin: 32px 0px 16px 40px;
}

.details-main {
  margin: 32px 40px 40px 40px;
}

.details-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.details-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.details-card {
  min-width: fit-content;
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.details-account-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 32px; */
}

.details-action,
.details-action-ad {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.details-action,
.details-action-google {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.details-action button {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #B72025;
  padding: 12px 16px 12px 32px;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  transition: background-color 0.1s linear;
}

.details-action button::before {
  content: url('../../images/icons/Plus Red.svg');
  position: absolute;
  left: 16px;
  top: 12px;
}

.details-action button:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
  cursor: pointer;
}

.details-action button:not(:disabled):hover::before {
  content: url('../../images/icons/Plus.svg');
}

.details-action-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.details-action-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;

  color: #5D5B5B;
}

.details-list {
  display: grid;
  grid-template-columns: minmax(240px, 1fr) minmax(240px, 1fr) minmax(240px, 1fr);
  gap: 32px;
}

.details-item {
  min-height: 40px;
  /* min-width: 300px; */
  min-width: 250px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
}

.details-wide-item-azure {
  min-height: 40px;
  min-width: 250px;
  max-width: 250px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
  white-space: normal;
  word-wrap: break-word; 
  overflow: visible;
  text-overflow: clip;
}

.details-item-azure {
  min-height: 40px;
  min-width: 100px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
}

.details-item-button {
  min-width: fit-content;
}

.details-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.details-value-company {
  font-weight: 700;
  font-size: 14px;
}

.button-link img {
  display: inline;
  margin-right: 6px;
  width: 12px;
  height: 12px;
}

.button-link button {
  width: 76px;
  height: 40px;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
}

.button-link button:hover {
  border: 2px solid #A4A3A3;
}

.account-details-card,
.account-details-card-users {
  padding: 32px 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.account-details-card {
  margin-bottom: 24px;
}

.account-details-table {
  border-collapse: collapse;
  width: 100%;
  min-width: 1100px;
}

.table-th-account-details {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
}

.not-clickable-account-details th,
.not-clickable-account-details-details th {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}

.account-details-table td,
.account-details-table tr {
  z-index: 2;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
}

.tr-grid-account-details th,
.tr-grid-account-details td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tr-grid-account-details td {
  padding: 7px 0 7px 12px;
  cursor: default !important;
}

.tr-grid-account-details th {
  position: -webkit-sticky;
  position: sticky;
  top: 63px;
  padding: 11px 0 11px 12px;
  text-align: left;
  background: #f3f3f3 !important;
  z-index: 3;
}

.last-actions {
  position: relative;
  padding: 11px 40px !important;
  text-align: center !important;
  align-items: center !important;
}

.tr-account-details {
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 0;
}

.tr-account-details td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}


.table-body-account-details td span {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #9D1B1F;
}

.card-action-modal button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.card-action-modal button:not(:disabled):hover {
  background-color: #9D1B1F !important;
}

.card-action-modal-remove {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.card-action-modal-remove button {
  width: 200px;
  height: 48px;
  border: 2px solid transparent;
  background-color: #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;

  transition: background-color .3s;
}

.card-action-modal-remove button:last-of-type {
  background-color: #C2C1C1;
}

.card-action-modal-remove button:not(:disabled):hover {
  background-color: #9D1B1F !important;
}

.card-action-modal-remove button:last-of-type:hover {
  background-color: #888787 !important;
}

.card-action-modal-remove button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.card-action-modal-remove button:active {
  transform: translateY(2px);
}

.role-select,
.tenant-select {
  width: 404px;
  margin-bottom: 25px;
}

.role-label::before {
  position: relative;
  width: 11px;
  height: 8px;
  top: 3px;
  margin-right: 5px;
  content: url('../../images/icons/user-role.svg');
}

.tenant-label::before {
  position: relative;
  top: 5px;
  left: 0;
  margin-right: 5px;
  content: url('../../images/icons/noun-tenant.svg');
}

.role-select::before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  left: 10px;
  content: url('../../images/icons/role.svg');
}

.tenant-select::before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  left: 10px;
  content: url('../../images/icons/tenant-icon.svg');
}

.role-select-container,
.tenant-select-container {
  position: relative;
}

.tenant-select-container {
  margin-bottom: 10px;
}

.role-select-container > p,
.tenant-select-container > p {
  position: absolute;
  left: 3px;
  top: 66px;
}

.role-select-container span,
.checkbox-select-container span,
.tenant-select-container span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: 0.02em;
  color: #343232;
}

.checkbox-select-container {
  margin-bottom: 16px;
}

.caption {
  display: inline;
}

.tenant-select-description {
  margin: 20px auto;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  text-align:justify;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.tenant-select-description:before {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  content: url('../../images/icons/Info\ Circle\ Red.svg');
  transform: rotate(180deg);
}

.slider-checkbox-td,
.slider-checkbox-th {
  position: relative;
  text-align: center !important;
}

.slider-checkbox-th--disabled,
.slider-checkbox-td--disabled {
  display: none;
}

.slider-checkbox-th--disabled p {
  visibility: hidden;
  width: 0;
}

.slider-checkbox {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
  position: relative;
  width: 36px;
  height: 18px;
}

.slider-checkbox-permissions {
  margin-right: 12px;
}

.slider-checkbox input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 18px;
}

.slider:not(.slider-checkbox-disabled) {
  cursor: pointer;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.slider-checkbox input:checked + .slider {
  background-color: #B72025;
}

.slider-checkbox input:checked + .slider:before {
  transform: translateX(18px);
}

.slider-checkbox-disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.slider-checkbox-disabled:hover {
  cursor: not-allowed !important;
}

.mini-loader {
  position: absolute;
  right: -25px;
  width: 18px;
  height: 18px;
  border: 3px solid #ccc;
  border-bottom-color: #B72025;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.role-edit-button {
  position: relative;
  border: none;
  background: transparent;
  transition: .2s ease-in-out;
}

.role-edit-button::after {
  display: block;
  content: url('../../images/icons/settings-ui.svg');
  transform: rotate(-180deg) translateY(2px);
  transition: .2s ease-in-out;
  cursor: pointer;
}

.role-edit-button:active {
  transform: scale(1.2);
  transition: .2s ease-in-out;
  cursor: pointer;
}
.role-edit-button:hover::after,
.role-edit-button-focus::after {
  content: url('../../images/icons/settings-ui red.svg');
  transform: rotate(-90deg) translateY(3px);
  transition: .2s ease-in-out;
}

.role-edit-button-remove::after {
  content: url('../../images/icons/settings-ui red.svg');
  transform: rotate(-270deg) translateY(2px) translateX(2px);
  transition: .2s ease-in-out;
}

.role-edit-button--disabled {
  pointer-events: none;
  opacity: 0.4;
}

.edit-access-form {
  align-items: flex-start;
}

.edit-access-form .title {
  display: block;
  margin: 0 auto 32px;
}

.slider-checkbox-edit-admin {
  margin-left: 10px;
}

.button-remove {
  display: block;
  background-color: #C2C1C1;
  width: 90px;
  height: 30px;
  line-height: 22px;
  margin: auto;
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  opacity: 0.8;
}

.button-remove:hover {
  opacity: 1;
  background-color: #c0392b;
}

.button-remove:active {
  transform: translateY(2px);
}

.button-remove .remove, .button-remove .icon-remove {
  display: block;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
}

.button-remove .remove {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 72%;
  line-height: inherit;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  left: 0;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.button-remove .remove:after {
  content: '';
  background-color: #fff;
  width: 2px;
  height: 70%;
  position: absolute;
  top: 15%;
  right: -1px;
}

.button-remove .icon-remove {
  width: 28%;
  right: 1px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.button-remove .icon-remove .fa {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.button-remove .icon-remove .fa-remove {
  height: 30px;
}

.button-remove .icon-remove .fa-check {
  display: none;
}

.button-remove.success .remove, .button-remove:hover .remove {
  left: -72%;
  opacity: 0;
}

.button-remove.success .icon-remove, .button-remove:hover .icon-remove {
  width: 100%;
}

.button-remove.success .icon-remove .fa, .button-remove:hover .icon-remove .fa {
  font-size: 16px;
}

.button-remove.success {
  background-color: #27ae60;
}

.button-remove.success .icon-remove .fa-remove {
  display: none;
}

.button-remove.success .icon-remove .fa-check {
  display: flex;
  align-items: center;
  height: 30px;
}

.button-remove:hover .icon-remove .fa-remove {
  height: 30px;
}

.button-remove:active {
  opacity: 1;
}

.fa-remove:before {
   content: "\2716"
}

.fa-check:before {
   content: "\2714"
}

.remove-form p {
  text-align: center;
}

.remove-form {
  margin-bottom: 32px;
}
