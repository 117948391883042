.history-card-analytics {
  width: 100%;
  min-width: 1200px;
  padding: 0;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px #00000014;
  animation: appear 300ms ease-in-out;
}

.history-card-analytics .history-description {
  padding: 0 20px 20px 20px;
  margin: 0;
  font-size: 14px;
    font-weight: 500;
    color: #888888;
}

.user-analytics-table-container {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  z-index: 6;
  background-color: #FFFFFF;
}

.user-analytics-table-container h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

.analytics-unique-emails {
  color: #858484;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.analytics-unique-emails span {
  padding-right: 10px;
}

.analytics-select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.analytics-users-table {
  position: relative;
  display: grid;
  border-collapse: collapse;
  min-width: 740px;
  width: 100%;
  grid-template-columns:
    minmax(180px, 1.5fr)
    minmax(180px, 2fr)
    minmax(180px, 2fr)
    150px
    155px
    150px
    40px;
  border-top: none;
  border-radius: 4px;
}

.analytics-users-table--loading {
  padding: 40px;
}

.table-th-analytics {
  display: contents;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.table-th-analytics,
.table-body-analytics,
.tr-grid-analytics,
.tr-grid-analytics-users
 {
  display: contents;
}

.tr-grid-analytics-users-last {
  border-top: 1px solid #E6E6E6;
  border-bottom: none;
}

.tr-grid-analytics th,
.tr-grid-analytics td,
.tr-grid-analytics-users th,
.tr-grid-analytics-users td
{
  overflow: hidden;
  text-overflow: ellipsis;
}

.tr-grid-analytics td,
.tr-grid-analytics-users td {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 8px 20px;
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;

  border-bottom: 1px solid #00000026;
}

.tr-grid-analytics-users td,
.tr-grid-analytics-users th {
  text-align: left;
  justify-content: start;
}

.tr-grid-analytics-users th {
  position: -webkit-sticky;
  position: sticky;
  top: 142px;
  z-index: 5;
}

.tr-grid-analytics-users td:nth-of-type(7),
.tr-grid-analytics-users th:nth-of-type(7)
{
  text-align: center;
  justify-content: center;
}

.tr-grid-analytics th,
.tr-grid-analytics-users th
{
  padding: 12px 20px;
  text-align: left;
  border-bottom: 1px solid #00000026;
  z-index: 4;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  background: #FCFCFD;
}

.tr-grid-analytics-users th {
  border-top: 1px solid #00000026;
}

.tr-grid-analytics-users:last-of-type .analytics-title-td {
  border-top: 1px solid #00000026;
}

.tr-grid-analytics-users:last-of-type .analytics-title-td {
  border-top: 1px solid #00000026;
}

.tr-analytics {
  background-color: #00000026;
  width: fit-content;
}

.list-td-analytics {
  position: relative;
  color: #5D5B5B;
  transition: background-color .2s ease-in-out;
}

.list-td-analytics--open {
  background-color: #FBEDED;
  border-bottom: none !important;
}

.list-td-analytics--open,
.analytics-title-td--open,
.analytics-users-table-additional--open {
  z-index: 4;
  position: -webkit-sticky;
  position: sticky;
  top: 186px;
}

.table-option-analytics {
  position: absolute;
  display: flex;
  border: none;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #B72025;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: bottom .2s ease-in-out;
}

.table-option-analytics:hover,
.table-option-analytics--active {
  color: #FFFFFF;
  background-color: #B72025;
}

.table-option-analytics--active {
  bottom: -2px;
}

.analytics-list::after {
  margin-left: 4px;
  position: relative;
  background-image: url('../../../images/icons/arrow.svg');
  background-size: 10px 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  content:"";
}

.analytics-active-list::after {
  background-image: url('../../../images/icons/up-arrow.svg');
}

.analytics-title-td,
.analytics-users-table-additional {
  z-index: 3;
}

.analytics-title-td--open {
  top: 238px;
  background-color: #fff;
  border: 1px solid #D74A48 !important;
  border-bottom: 1px solid #D6D6D6 !important;
  border-radius: 4px 4px 0 0 !important;
  cursor: url('../../../images/icons/noun-close-tab.svg'), auto;
}

.analytics-users-table-additional--open {
  top: 282px;
}

.analytics-title-td span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  width: 100%;
  border-bottom: 1px solid #00000026;
  color: #B72025;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  border: 0 !important;
}

.analytics-title-td {
  grid-column: 1 / -1;
  padding: 0;
}

.analytics-users-table-additional {
  grid-column: 1 / -1;
  border: 1px solid #D74A48 !important;
  border-top: none !important;
  border-radius: 0 0 4px 4px;
  background-color: #FBFBFB;
  padding: 0 !important;
}

.analytics-users-table-additional ul {
  width: 100%;
}

.analytics-li {
  display: grid;
  grid-template-columns:
    minmax(150px, 2fr)
    minmax(150px, 2fr)
    minmax(150px, 2fr);
  padding: 10px 20px;
  border-bottom: 1px solid #00000026;
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px
}

.analytics-li span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.analytics-li:last-of-type {
  border: none;
}

.analytics-ul-title {
  font-weight: 600;
  padding: 10px 20px;
  border-bottom: 1px solid #00000026;
  background-color: #F3F3F3;
}

.false-positive-anylytics {
  text-decoration: line-through;
}

.action-type-select--disabled,
.email-type-select--disabled {
  opacity: 0.7;
  pointer-events: none;
  user-select: none;
}

.user-analytics-table-container button {
  margin: 0;
  padding: 9px 20px;
  display: flex;
  width: 135px;
  border: 1px solid #C42D28;
  border-radius: 100px;
  background-color: #C42D28;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  animation: appear .2s ease-in-out;
}

.user-analytics-table-container button svg {
  position: relative;
  margin-right: 6px;
}

.user-analytics-table-container button:hover {
  background-color: #FFF;
  color: #C42D28;
}

.user-analytics-table-container button svg path {
  transition: fill .2s ease-in-out;
}

.user-analytics-table-container button:hover svg path {
  fill: #C42D28;
}

.user-analytics-table-container button:active {
  transform: translateY(2px);
}

.user-analytics-table-container .csv-download-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: #FFF;
}

.history-card-analytics .input-container-select-analytics-item--email-type .react-select__control,
.history-card-analytics .input-container-select-analytics-item--action-type .react-select__control
 {
  width: 220px;
  height: 35px;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
}

.history-card-analytics .input-container-select-analytics-item--email-type .react-select__value-container::before {
  content: url('../../../images/icons/stacked_email.svg');
  top: 2px;
}

.history-card-analytics .input-container-select-analytics-item--action-type .react-select__value-container::before {
  content: url('../../../images/icons/bubble.svg');
  top: 2px;
}

.analytics-users-table .list-td-analytics .alert-resend-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  transition: transform .2s ease-in-out;
}

.analytics-users-table .list-td-analytics .alert-resend-button:hover {
  transform: scale(1.3);
}

.analytics-users-table .list-td-analytics .alert-resend-button:active {
  transform: scale(1);
}
