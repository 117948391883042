.trend-graphs-container-item-table {
  position: relative;
  overflow-y: auto;
}

.trend-graphs-container-item-table span {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.trend-graphs-container-item-table .high-risk-user span,
.compliance-table .high-risk-user span {
  padding: 2px 8px;
  border-radius: 16px;
  font-weight: 600;
  background: #FBEDED;
  color: #B72025;
}

.trend-graphs-container-item-table .moderate-risk-user span {
  padding: 2px 8px;
  border-radius: 16px;
  font-weight: 600;
  background: #FFF5E6;
  color: #ED8F00;
}

.trend-graphs-container-item-table .low-risk-user span,
.compliance-table .low-risk-user span {
  padding: 2px 8px;
  border-radius: 16px;
  font-weight: 600;
  background: #ECFDF3;
  color: #027A48;
}

.trend-graphs-container-item-graph {
  margin-bottom: 20px;
}

.chart-container-risk-pie {
  position: relative;
  width: 70%;
}

.chart-container-risk-pie .additional-data-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
}

.trend-graphs-container-item-chart {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
}

.trend-graphs-container-item-chart h4 {
  margin-bottom: 5px;
  display: block;
  width: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.chart-container-risk-pie .analytics-metrics-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.chart-container-risk-pie .analytics-metrics-value-current {
  margin-bottom: 5px;
  color: #000;
  animation: appear 300ms ease-in-out;
}

.chart-container-risk-pie .analytics-metrics-value-prev {
  font-size: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.7;
  animation: appear 300ms ease-in-out;
}

.chart-container-risk-pie .analytics-metrics-value-prev--positive {
  color: #008000;
  opacity: 1;
}
.chart-container-risk-pie .analytics-metrics-value-prev--negative {
  color: #de2c37;
  opacity: 1;
}
