.groups-main {
  min-width: 510px;
  width: 100%;
  padding: 24px;
  margin-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.group-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}

.groups-description {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.group-button {
  padding: 12px 25px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.group-button-simple {
  margin-bottom: 0;
}
.group-button:disabled {
  cursor: not-allowed;
}

.group-button:not(.group-button-simple):before {
  width: 10px;
  height: 10px;
  position: relative;
  margin-right: 7px;
  content: url('./../../images/icons/Plus.svg');
}

.group-button:hover {
  background-color: #9D1B1F;
}

.group-button:active {
  background-color: #B72025;
}

.group-button:disabled {
  background-color: #EFB7B6
}

.group-select {
  position: relative;
  z-index: 2;
}

.group-select::before {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 10px;
  left: 13px;
  z-index: 2;
  content: url('../../images/icons/Groups.svg')
}

.group-users-table {
  margin-bottom: 25px;
  width: 100%
}

.group-users-not-clickable {
  background-color: #F3F3F3;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s
}

.group-selected,
.group-selected:hover {
  background-color: #ffdede;
}

.group-users-tr {
  display: grid;
  grid-template-columns: 2fr 5fr 4fr 4fr 3fr 3fr;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  padding: 9px 0 9px 12px;
  width: 100%;
  min-height: 40px;
  text-align: left;
}

.group-users-tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  text-align: left;
}

.group-users-tr th:last-of-type {
  margin: 0;
}

.group-users-tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.group-users-tr td:not(:first-of-type) {
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}

.group-td-email {
  color: #5D5B5B
}

.group-users-not-clickable button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.group-users-not-clickable button:hover {
  color: #9D1B1F
}

.group-users-not-clickable button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.group-users-not-clickable button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px
}

.group-sync-title {
color: green;
}

.group-sync-tenant {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-top: 8px;
}

.group-edit-delete {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  cursor: pointer;
  color: #B72025;
}
.group-edit-delete:hover {
 text-decoration: underline;
}
