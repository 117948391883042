.billing-card {
  min-width: 500px;
  width: 100%;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.billing-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.billing-table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.billing-table {
  margin: 16px 0 0 0;
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns: 
    minmax(330px, 6fr)
    minmax(130px, 3fr)
    minmax(150px, 2fr)
    minmax(150px, 3fr)
    minmax(100px, 1fr)
    minmax(120px, 1fr)
    minmax(120px, 3fr);
  border: 1px solid #D6D6D6;
  border-radius: 4px;
}

.invoices-table {
  grid-template-columns: 
    minmax(330px, 6fr)
    minmax(130px, 3fr)
    minmax(150px, 2fr)
    minmax(150px, 3fr)
    minmax(120px, 1fr)
    minmax(120px, 3fr);
}

.table-th-billing {
  display: contents;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.tr-grid-billing th,
.tr-grid-billing td {
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.tr-grid-billing td {
  display: flex;
  align-items: center;
  padding: 7px 0 7px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  border-bottom: 1px solid #E6E6E6;
}

.tr-grid-billing td:first-of-type::before {
  content: url('../../../images/icons/File.svg');
  position: relative;
  top: 2px;
  margin-right: 4px;
  opacity: 0.7;
}

.tr-grid-billing td:not(:first-of-type),
.tr-grid-billing th:not(:first-of-type) {
  text-align: center;
  justify-content: center;
}

.tr-grid-billing td:nth-of-type(3),
.tr-grid-billing td:nth-of-type(4),
.tr-grid-billing td:nth-of-type(5) {
  font-weight: 500;
  color: #343232;
}

.tr-grid-billing th {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  padding: 11px 0 11px 12px;
  text-align: left;
  background: #F3F3F3;
  border-bottom: 1px solid #E6E6E6;
}

.table-th-billing,
.table-body-billing,
.tr-grid-billing {
  display: contents;
}

.tr-billing {
  background: #F3F3F3;
  border-bottom: 1px solid #E6E6E6;
}

.billing-status {
  padding: 3px 10px;
  background: #F3F3F3;
  border-radius: 4px;
}

.billing-status::first-letter {
  text-transform: capitalize;
}

.billing-button {
  padding: 4px 8px;
  background-color: #FFFFFF;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  transition: background-color .2s ease-out;
}

.billing-button::before {
  content: url('../../../images/icons/download.svg');
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.billing-button:hover {
  background-color: #F3F3F3;
}