.support-main {
  margin: 32px 40px 40px 40px;
}

.support-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.support-card {
  width: 550px;
  padding: 24px;
  margin-bottom: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.support-card > h4 {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.support-card > p {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.support-button {
  padding: 11px 16px;
  background-color: #B72025;
  border: 1px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.support-button:hover {
  background-color: #FFFFFF;
  color: #B72025;
}
.support-button:active {
  animation: gradient 150ms;
  transform: scale(0.99);
}

.support-select {
  margin-bottom: 12px;
  width: 320px;
  min-height: 40px;
}

.support-select > div {
  padding-left: 0;
}

.support-label-span,
.support-card label {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.support-label-span::after,
.support-card label::after {
  content: '*';
  color: #DE2C37;
  margin-left: 4px;
}

.support-card textarea {
  margin-bottom: 6px;
  padding: 9px 12px;
  max-width: 502px;
  min-width: 502px;
  min-height: 130px;
  border: 1px solid #D6D6D6;
  border-radius: 4px;

  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.support-card textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.support-card textarea:focus {
  outline: none !important;
  border-color: #343232;
}

.attach-button-support {
  position: relative;
  padding: 7px 12px 7px 30px;
  margin-right: 6px;
  margin-bottom: 16px;
  background-color: #FFFFFF;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  transition: .1s ease-in-out;
}

.attach-button-support::before {
  position: absolute;
  content: url('../../images/icons/attach.svg');
  left: 11px;
  top: 9px;
}

.attach-button-support:hover {
  color: #343232;
  border-color: #343232;
}

.attach-button-support:hover::before {
  content: url('../../images/icons/attach black.svg');
}

.attach-button-support:active {
  transform: scale(0.9);
}

.attach-button-support-cross {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  transform: translateY(3px);
  cursor: pointer;
  transition: .1s ease-in-out;
}

.attach-button-support-cross:before {
  content: url('../../images/icons/Cross rotate.svg');
}
.attach-button-support-cross:hover::before {
  content: url('../../images/icons/Cross rotate black.svg');
}

.support-actions {
  display: flex;
  justify-content: space-between;
}

.support-button-submit {
  position: relative;
  padding: 12px 25px 12px 42px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.support-button-submit::before {
  position: absolute;
  content: url('../../images/icons/Check.svg');
  top: 11px;
  left: 25px;
}
.support-button-submit:not(.support-button-submit-loading, :disabled):hover:before {
  content: url('../../images/icons/Check red normal.svg');
  margin-right: 5px;
}

.support-button-submit:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.support-button-submit-loading {
  opacity: 1 !important;
}

.support-button-submit:not(.support-button-submit-loading, :disabled):hover {
  background-color: #FFFFFF;
  color: #B72025;
}

.support-button-submit:not(.support-button-submit-loading, :disabled):active {
  animation: gradient 150ms;
  transform: scale(0.99);
}

.support-button-cancel {
  background-color: transparent;
  border: transparent;
  color: #5D5B5B;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  cursor: pointer;
  transition: .1s ease-in-out;
}

.support-button-cancel:hover {
  color: #343232;
}

.support-button-cancel::before {
  display: inline-block;
  margin-right: 4px;
  content: url('../../images/icons/Cross rotate.svg');
  transform: translateY(3px);
}

.support-button-cancel:hover::before {
  content: url('../../images/icons/Cross rotate black.svg');
}

.support-button-cancel:active {
  transform: scale(0.9);
}

.support-card-table {
  width: 100%;
  padding: 32px 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
  margin-bottom: 24px;
}

.support-card-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.support-table {
  margin: 16px 0 0 0;
  display: grid;
  border-collapse: collapse;
  min-width: 1040px;
  width: fit-content;
  grid-template-columns:
    minmax(160px, 1fr)
    minmax(100px, 1fr)
    minmax(672px, 5fr)
    minmax(140px, 2px);
  border: 1px solid #D6D6D6;
  border-radius: 4px;
}

.table-th-support {
  display: contents;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.tr-grid-support th,
.tr-grid-support td {
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.tr-grid-support td {
  display: flex;
  align-items: center;
  padding: 7px 0 7px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;

  border-bottom: 1px solid #E6E6E6;
}

.tr-grid-support td:not(:last-of-type) {
  border-right: 1px solid #E6E6E6;
}

.tr-grid-support td:nth-of-type(2),
.tr-grid-support td:last-of-type {
  font-weight: 600;
}

.tr-grid-support td > div {
  padding: 2px 10px;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
}

.tr-grid-support td:last-of-type,
.tr-grid-support th:last-of-type {
  text-align: center;
  justify-content: center;
}

.tr-grid-support th {
  padding: 11px 0 11px 12px;
  text-align: left;
  background: #F3F3F3;
  border-bottom: 1px solid #D6D6D6;
  z-index: 2;
}

.tr-grid-support th:not(:last-of-type) {
  border-right: 1px solid #D6D6D6;
}

.table-th-support,
.table-body-support,
.tr-grid-support {
  display: contents;
}

.tr-support {
  background: #F3F3F3;
  width: fit-content;
}

.tr-grid-support td > .support-status {
  position: relative;
  padding: 2px 10px 2px 24px;
  border: none;
}

.support-status-pending {
  background-color: #F3F3F3;
  color: #5D5B5B;
}

.support-status-in-progres {
  background-color: #FFF5E6;
  color: #ED8F00;
}

.support-status-completed {
  background-color: #EAFAF1;
  color: #28B865;
}

.support-status-pending::before {
  position: absolute;
  content: url('../../images/icons/Clock.svg');
  top: 4px;
  left: 8px;
}

.support-status-in-progres::before {
  position: absolute;
  content: url('../../images/icons/Sync yellow.svg');
  top: 4px;
  left: 8px;
}

.support-status-completed::before {
  position: absolute;
  content: url('../../images/icons/Check\ Circle\ Green\ little.svg');
  top: 4px;
  left: 8px;
}

.text-cell {
  position: relative;
  padding-bottom: 30px !important;
}

.text-content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-content.expanded {
  display: block;
}

.expand-button {
  padding: 0;
  display: block;
  position: absolute;
  bottom: 6px;
  background-color: transparent;
  border: none;
  color: #B72025;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.expand-button::after {
  position: relative;
  display: inline-block;
  margin-left: 2px;
  width: 14px;
  height: 14px;
  content: url('../../images/icons/caret-right.svg');
  top: 3px
}

.expand-button.expanded::after {
  transform: rotate(180deg);
}

.expand-button:not(.expanded):hover {
  transform: translateX(3px);
}

.expand-button.expanded:hover {
  transform: translateX(-3px);
}