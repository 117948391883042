.false-positive-tooltip,
.permissions-tooltip,
.date-range-tooltip {
  text-align: center;
}

.permissions-check,
.permissions-cross {
  margin-right: 5px;
  font-weight: 700;
}

.permissions-check {
  color: #28B865;
}

.permissions-cross {
  color: #DE2C37;
}
