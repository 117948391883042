.frequency-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px 32px 32px;

  background-color: #FFF;
  border-radius: 4px;
}

.frequency-content {
  margin-bottom: 12px;
}

.frequency-modal .card-title {
  display: block;
  margin-bottom: 8px;
  text-align: left;
  color: #343232;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.frequency-modal .card-subtitle {
  display: block;
  text-align: left;
  color: #B72025;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.frequency-modal-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.frequency-modal-buttons button {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 2px solid transparent;
  background-color: #B72025;
  border-radius: 4px;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  cursor: pointer;

  transition: background-color .3s;
}

.frequency-modal-buttons button:first-of-type:before {
  content: url('../../images/icons/Check-white-small.svg');
  position: relative;
  top: 3px;
}

.frequency-modal-buttons button:last-of-type {
  background-color: #FFF;
  border: 1px solid #B72025;
  transition: all 0.2s;
  color: #B72025;
}

.frequency-modal-buttons button:not(:disabled):hover {
  background-color: #9D1B1F !important;
}

.frequency-modal-buttons button:last-of-type:hover {
  background-color: #9D1B1F;
  color: #FFFFFF;
}

.frequency-modal-buttons button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.frequency-modal-buttons button:not(:disabled):active {
  transform: translateY(2px);
}

.frequency-input-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  color: #858484;
}

.frequency-input {
  border: none;
  border-bottom: 2px solid #9D1B1F;
  width: 50px;
  color: #B72025;
  text-align: center;
}

.frequency-input:focus {
  outline: none;
}

.frequency-input-div input::-webkit-outer-spin-button,
.frequency-input-div input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
