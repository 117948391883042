.user-main {
  margin: 32px 40px;
  min-width: 600px;
}

.user-main-title {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.user-main-description {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.user-main ol {
  margin: 0 0 32px 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.user-main li {
  margin-bottom: 24px;
}

.user-main li span {
  display: inline-block;
  margin-bottom: 8px;
}

.button-setup button {
  padding: 12px 16px 12px 18px;
  box-sizing: border-box;
  min-width: 222px;
  height: 43px;
  background-color: #B72025;
  border: 2px solid transparent;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.2s;
}

.user-main-link button {
  padding: 7px 12px 7px 30px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;

  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.user-main-link button:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
}

.user-main-link button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.user-main-link .btn-ad::before {
  content: url('../../images/icons/Azure.svg');
  position: absolute;
  left: 10px;
  top: 8px;
}

.user-main-link .btn-google::before {
  content: url('../../images/icons/Google (colors).svg');
  position: absolute;
  left: 10px;
  top: 8px;
}

.user-main-link .btn-csv::before {
  content: url('../../images/icons/File Red.svg');
  position: absolute;
  left: 10px;
  top: 8px;
}

.user-main-link .btn-csv:not(:disabled):hover:before {
  content: url('../../images/icons/File White.svg');
}

.user-main-link .btn-all-tenants::before {
  content: url('../../images/icons/File Red.svg');
  position: absolute;
  left: 10px;
  top: 8px;
}

.user-main-link .btn-all-tenants:not(:disabled):hover:before {
  content: url('../../images/icons/File White.svg');
}
