.main-title {
  margin: 32px 0px 16px 40px;
}

.signature-main {
  margin: 32px 40px 40px 40px;
}

.signature-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.signature-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.signature-card-phish {
  display: flex;
  justify-content: space-between;
  min-width: fit-content;
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
  width:100%
}

.signature-account-list-phish {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 32px; */
}

.signature-action,
.signature-action-ad {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.signature-action,
.signature-action-google {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.signature-action button {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #B72025;
  padding: 12px 16px 12px 32px;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  transition: background-color 0.1s linear;
}

.signature-action button::before {
  content: url('../../images/icons/Plus Red.svg');
  position: absolute;
  left: 16px;
  top: 12px;
}

.signature-action button:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
  cursor: pointer;
}

.signature-action button:not(:disabled):hover::before {
  content: url('../../images/icons/Plus.svg');
}

.signature-action-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.signature-action-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;

  color: #5D5B5B;
}

.signature-list {
  display: grid;
  grid-template-columns: minmax(240px, 1fr) minmax(240px, 1fr) minmax(240px, 1fr);
  gap: 32px;
}

.signature-item {
  min-height: 40px;
  /* min-width: 300px; */
  min-width: 250px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
}

.signature-item-button {
  min-width: fit-content;
}

.signature-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.signature-value-role::first-letter {
  text-transform: capitalize;
}

.signature-value-company {
  font-weight: 700;
  font-size: 14px;
}

.button-link img {
  display: inline;
  margin-right: 6px;
  width: 12px;
  height: 12px;
}

.button-link button {
  width: 76px;
  height: 40px;

  border: 1px solid #A4A3A3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
}

.button-link button:hover {
  border: 2px solid #A4A3A3;
}

.account-signature-card,
.account-signature-card-users {
  padding: 32px 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.account-signature-card {
  margin-bottom: 24px;
}

.account-signature-table {
  border-collapse: collapse;
  width: 100%;
}

.table-th-account-details {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
}

.not-clickable-account-details th,
.not-clickable-account-signature-details th {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}

.account-signature-table td,
.account-signature-table tr {
  z-index: 2;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
}

.tr-grid-account-details th,
.tr-grid-account-details td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tr-grid-account-details td {
  padding: 7px 0 7px 12px;
  cursor: pointer;
}

.tr-grid-account-details th {
  position: -webkit-sticky;
  position: sticky;
  top: 63px;
  padding: 11px 0 11px 12px;
  text-align: left;
  background: #fff;
  z-index: 3;
}

.tr-account-details {
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 0;
}

.tr-account-details td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.table-body-account-details td:last-of-type::first-letter {
  text-transform: capitalize;
}

.table-body-account-details td span {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #9D1B1F;
}

.card-action-modal button {
  width: 404px;
  height: 48px;
  border: 2px solid transparent;
  background-color: #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;

  transition: background-color .3s;
}

.card-action-modal button:hover {
  background-color: #9D1B1F;
}

.card-action-modal button:active {
  background-color: #B72025;
}
