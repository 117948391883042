.google-verify-link {
  transform: translateY(24px);
}

.google-verify-link button {
  padding: 12px 16px 12px 32px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #A4A3A3;
  border-radius: 4px;

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.google-verify-link button:not(:disabled):hover {
  background-color: #5D5B5B;
  color: #FFFFFF;
}

.google-verify-link button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.google-verify-link .btn-google::before {
  content: url('../../images/icons/Google (colors).svg');
  position: absolute;
  left: 10px;
  top: 13px;
}

.btn-google-verified::before {
  content: url('../../images/icons/Check Circle Green.svg');
  position: absolute;
  left: 10px;
  top: 12px;
}

.input-field-tenant {
  display: grid;
  grid-template-columns: 10fr 1fr 10fr;
  gap: 10px;
}

.details-wide-item-google {
  min-height: 40px;
  min-width: 250px;
  max-width: 250px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
  white-space: normal;
  word-wrap: break-word; 
  overflow: visible;
  text-overflow: clip;
}

.details-item-google {
  min-height: 40px;
  min-width: 100px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
}

.details-item-button {
  min-width: fit-content;
}
