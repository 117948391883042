.logo-card {
  min-width: fit-content;
  padding: 32px 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.logo-card-details {
  margin-bottom: 24px;
}

.logo-card-title {
  margin-bottom: 16px;
}

.logo-card-title h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.logo-card-title p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.logo-card input::-webkit-file-upload-button {
  background-color: #FFFFFF;
  padding: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 12px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.logo-card input::-webkit-file-upload-button:hover {
  color: #FFFFFF;
  background-color: #5D5B5B;
}

.logo-card input[type=file] {
  position: relative;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}
