.billing-page {
  padding: 36px 38px;
}

.billing-page h2 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.billing-back-link {
  position: relative;
  display: block;
  max-width: max-content;
  margin-bottom: 22px;
  text-decoration: none;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025;
  transition: transform .1s linear;
}

.billing-back-link::before {
  content: url('../../../images/icons/Arrow\ Circle\ Left\ Red.svg');
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.billing-back-link:hover {
  transform: translateX(-3px);
}

.billing-cards {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.billing-cards-wrap {
  width: 100%;
}

.billing-card,
.succes-result-card,
.cancel-result-card {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.cancel-result-card,
.succes-result-card {
  min-width: 860px;
}

.billing-card {
  max-width: 500px;
  padding: 24px 28px;
  margin-bottom: 12px;
}

.succes-result-card {
  padding: 45px;
}

.cancel-result-card {
  padding: 116px;
}

.cancel-result-card-alert,
.succes-result-card-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cancel-result-card-alert img,
.succes-result-card-alert img {
  margin-bottom: 20px;
}

.cancel-result-card-alert h4,
.succes-result-card-alert h4 {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.cancel-result-card-alert span,
.succes-result-card-alert span {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #343232;
}

.succes-result-card-alert h2 {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  color: #343232;
}

.succes-result-card-alert p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.succes-result-card-alert p:first-of-type {
  margin-bottom: 40px;
}

.succes-result-card-alert p:nth-of-type(2) {
  margin-bottom: 12px;
}

.cancel-result-card-alert p,
.cancel-alert-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #B72025;
}

.cancel-alert-link {
  font-weight: 600;
}

.succes-result-link {
  margin-right: 26px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #B72025;
  text-decoration: none;
}

.succes-result-link:nth-of-type(1)::before {
  content: url('../../../images/icons/dissolving.svg');
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.succes-result-link:nth-of-type(2)::before {
  content: url('../../../images/icons/User\ Red\ Small.svg');
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.succes-result-link:nth-of-type(3)::before {
  content: url('../../../images/icons/History\ red\ Small.svg');
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.card-element {
  min-width: 700px;
}

.card-description {
  min-width: 400px;
}

.card-description li {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.card-description span {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  text-transform: capitalize;
}

.billing-card > h4 {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #343232;
}

.explanation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.divider {
  height: 1px;
  background: #D6D6D6;
  margin-bottom: 12px;
}

.total-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #343232;
}

.total-price-tax {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.total-price-tax div:nth-of-type(2) {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #858484;
}

.total-price-subtotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}

.total-price-subtotal>span:first-of-type {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.total-price-subtotal>span:last-of-type {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.submit-price-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  background-color: #B72025;
  border-radius: 4px;
  border: 1px solid #B72025;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  transition: all .1s linear;
}

.submit-price-button:not(:disabled):hover {
  background-color: #fff;
  color: #B72025;
}

.submit-price-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.billing-card-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.billing-card-description > a {
  text-decoration: none;
  font-weight: 600;
  color: #B72025;
}

.billing-page-questions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.billing-page-questions span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.billing-page-questions-link {
  display: block;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #B72025;
  transition: all .1s linear;
}

.billing-page-questions-link:hover {
  color: #B72025;
}