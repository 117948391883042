.logs-card {
  min-width: fit-content;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.logs-table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.logs-sync-button {
  width: 120px;
  height: 40px;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
}

.logs-sync-button:not(:disabled):hover {
  border: 2px solid #A4A3A3;
}

.logs-sync-button::before {
  width: 15px;
  height: 18px;
  position: relative;
  top: 3px;
  margin-right: 6px;
  content: url('../../images/icons/Sync.svg');
}

.logs-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 25px;
}

.tr-grid-logs th,
.tr-grid-logs td {
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.table-th-logs {
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
}

.tr-grid-logs td {
  padding: 8px 12px;
  cursor: pointer;
}
.tr-grid-logs td:last-of-type {
  padding: 0;
}
.tr-grid-logs th {
  position: -webkit-sticky;
  position: sticky;
  top: 63px;
  padding: 12px;
  text-align: left;
  background: #fff;
  z-index: 3;
}

.tr-logs,
.tr-logs-csv {
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 0;
}

.tr-logs td,
.tr-logs-csv td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.tr-logs td span:nth-of-type(2),
.tr-logs-csv td span:nth-of-type(2),
.tr-logs-csv-for-csv td span:nth-of-type(2) {
  color: #28B865;
}

.tr-logs td span:nth-of-type(3),
.tr-logs-csv td span:nth-of-type(3) {
  color: #DE2C37;
}

.tr-logs td:nth-of-type(3),
.tr-logs-csv td:nth-of-type(2),
.tr-logs-csv td:nth-of-type(3),
.tr-logs-csv td:nth-of-type(4),
.tr-logs-csv-for-csv td:nth-of-type(3),
.tr-logs-csv-for-csv td:nth-of-type(4),
.tr-logs-csv-for-csv td:nth-of-type(5)
{
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}
.tr-logs-csv td:nth-of-type(3),
.tr-logs-csv-for-csv td:nth-of-type(4) {
  font-weight: bold;
}

.tr-logs-csv td:nth-of-type(4) span,
.tr-logs-csv-for-csv td:nth-of-type(5) span {
  display: inline-flex;
  flex-direction: row;
}

.tr-logs-csv td:nth-of-type(4) span div:nth-of-type(1),
.tr-logs-csv-for-csv td:nth-of-type(5) span div:nth-of-type(1) {
  font-weight: bold;
  margin-right: 3px;
}

.tr-logs-csv td:nth-of-type(4) span div:nth-of-type(2),
.tr-logs-csv-for-csv td:nth-of-type(5) span div:nth-of-type(2) {
  margin-right: 3px;
}

.tr-logs-csv td:nth-of-type(1) {
  min-width: 200px;
}

.tr-logs-csv td:nth-of-type(2),
.tr-logs-csv-for-csv td:nth-of-type(3) {
  text-align: center;
  min-width: 150px;
}

.tr-logs-csv td:nth-of-type(3),
.tr-logs-csv-for-csv td:nth-of-type(4) {
  text-align: center;
  min-width: 30px;
}

.tr-logs-csv td:nth-of-type(5),
.tr-logs-csv-for-csv td:nth-of-type(6) {
  text-align: center;
  width: 243.6px;
}

.not-clickable-logs th,
.not-clickable-logs-details th {
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}

.not-clickable-logs th {
  text-align: center;
}

.clickable-row-logs td {
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
  transition: background-color .2s;
}

.tr-logs-active {
  position: sticky;
  top: 106px;
  z-index: 2;
}

.tr-logs-active,
.tr-logs-active:hover {
  background: #FBEDED;
}

.tr-logs-active td,
.tr-logs-active td:nth-of-type(3) {
  font-weight: 600;
  color: #343232;
}

.logsnavbar {
  display: flex;
  justify-content: center;
  transition: transform 0.1s;
  margin: 0 auto;
}

.logsnavbar-close path {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.logs-details {
  display: block;
}

.logs-details-table-tr {
  position: sticky;
  top: 142px;
  background-color: #F3F3F3;
  z-index: 2;
}

.logs-details-error-description, .logs-details-created-description {
  padding: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
  background-color: #F3F3F3;
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
  border: none;
}

.logs-details-created-description td::before {
  content: url('../../images/icons/Check\ Circle\ Green.svg');
  margin-right: 4px;
  position: relative;
  top: 3px
}

.logs-details-created-description td {
  padding: 12px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
}
.logs-details-error-description td {
  padding: 12px;
  border-width:1px;
  border-style: solid;
  border-color: #E6E6E6;
}
.logs-details-created-description td {
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
}
.logs-details-created-description td {
  padding: 12px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
}
.logs-details-created-description td {
  padding: 12px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
}

.logs-details-error-description td::before {
  content: url('../../images/icons/Alert triangle 2.svg');
  margin-right: 4px;
  position: relative;
  top: 3px
}

.logs-details-created-description td::before {
  content: url('../../images/icons/Check\ Circle\ Green.svg');
  margin-right: 4px;
  position: relative;
  top: 3px
}

.logs-button-open {
  display: block;
  background: none;
  border: none;
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  transition: transform 0.1s linear;
}

.logs-button-open:disabled {
  opacity: 0.6;
}

.logs-button-open:not(:disabled):hover {
  transform: scale(1.08);
}

.logs-details-table {
  position: relative;
  top: -3px;
  width: 100%;
  height: 240px;
  background-color: #F3F3F3;
  border-collapse: collapse;
}

.logs-details-table th {
  padding: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.logs-details-table td,
.logs-details-table tr {
  z-index: 2;
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
}

.logs-details-table td {
  padding: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.errors-table {
  display: table;
}

.table-button-container {
  display: flex;
  margin: 0 auto;
  min-width: max-content;
  height: auto;
}

.logs-table-option {
  display: flex;
  border: none;
  background-color: #FFFFFF;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
}

.logs-table-option:enabled {
  color: #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s linear;
  text-align: center;
}

.logs-table-option:hover:enabled {
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
}

.logs-table-option-new-records:enabled {
  color: #5D5B5B;
}

.sync-log-button-error, .sync-log-button-new {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sync-log-button-error::before {
  content: url('../../images/icons/Alert triangle 2.svg');
  margin-right: 4px;
  position: relative;
  top: 1px
}

.sync-log-button-new::after, .sync-log-button-error::after {
  margin-left: 4px;
  margin-top: 1px;
  position: relative;
  background-image: url('../../images/icons/arrow.svg');
  background-size: 10px 10px;
  display: inline-block;
  width: 10px; 
  height: 10px;
  content:"";
}

.active-list::after {
  margin-left: 4px;
  position: relative;
  background-image: url('../../images/icons/up-arrow.svg');
  background-size: 10px 10px;
  display: inline-block;
  width: 10px; 
  height: 10px;
  content:"";
}

.logs-table-option-new-records:before {
  content: url('../../images/icons/Add\ user.svg');
  margin-right: 4px;
  position: relative;
  top: 2px
}
.logs-table-option-new-records:disabled:before {
  content: url('../../images/icons/Add\ user 2.svg');
}

.td-summary {
  background-color: #F3F3F3;
}

.td-summary button {
  background-color: transparent;
}

.log-date {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.log-year {
  color: #5D5B5B !important;
}

.logs-details-table-check {
  color: #28B865;
  font-weight: 700;
}

.logs-details-table-cross {
  color: #DE2C37;
  font-weight: 700;
}

.logs-table-option-divider {
  width: 1px;
  background-color: #E6E6E6;
}
