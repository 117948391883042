.api-mamagment-main {
  margin: 32px 40px 40px 40px;
}

.api-mamagment-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.api-mamagment-card {
  padding: 32px 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
  margin-bottom: 24px;
}

.api-mamagment-card-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  margin-bottom: 16px;
}

.api-mamagment-container {
  width: max-content;
}

.api-mamagment-card-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.api-mamagment-key-background {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-bottom: 16px;
  background-color: #FFFFFF;
  padding: 9px 12px;
  width: max-content;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.api-mamagment-key-background > img {
  position: relative;
  cursor: copy;
  opacity: 0.7;
  transition: opacity .1s ease-in-out;
}

.api-mamagment-key-background > img:hover {
  opacity: 1;
}

.api-mamagment-card-action > span {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.api-mamagment-card-action > p {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.api-mamagment-card-action button {
  width: max-content;
  padding: 11px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFF;
  background-color: #B72025;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.api-mamagment-card-action button:not(:disabled):hover {
  background-color: #FFF;
  color: #B72025;
}
.api-mamagment-card-action button:not(:disabled):active {
  animation: gradient 150ms;
  transform: scale(0.99);
}

.api-mamagment-card-action button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.no-select {
  user-select: none;
}

.allow-select {
  user-select: text;
}

.api-mamagment-card-actions {
  position: relative;
}

.api-mamagment-button-remove {
  display: block;
  background-color: #C2C1C1;
  width: 90px;
  height: 40px;
  line-height: 22px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  opacity: 0.8;
}

.api-mamagment-button-remove:hover {
  opacity: 1;
  background-color: #B72025;
}

.api-mamagment-button-remove:active {
  transform: translateY(2px);
}

.api-mamagment-button-remove .remove, .api-mamagment-button-remove .icon-remove {
  display: block;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
}

.api-mamagment-button-remove .remove {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 72%;
  line-height: inherit;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  left: 0;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.api-mamagment-button-remove .remove:after {
  content: '';
  background-color: #fff;
  width: 2px;
  height: 70%;
  position: absolute;
  top: 15%;
  right: -1px;
}

.api-mamagment-button-remove .icon-remove {
  width: 28%;
  right: 1px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.api-mamagment-button-remove .icon-remove .fa {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.api-mamagment-button-remove .icon-remove .fa-remove {
  height: 40px;
}

.api-mamagment-button-remove .icon-remove .fa-check {
  display: none;
}

.api-mamagment-button-remove.success .remove, .api-mamagment-button-remove:hover .remove {
  left: -72%;
  opacity: 0;
}

.api-mamagment-button-remove.success .icon-remove, .api-mamagment-button-remove:hover .icon-remove {
  width: 100%;
}

.api-mamagment-button-remove.success .icon-remove .fa, .api-mamagment-button-remove:hover .icon-remove .fa {
  font-size: 16px;
}

.api-mamagment-button-remove.success {
  background-color: #27ae60;
}

.api-mamagment-button-remove.success .icon-remove .fa-remove {
  display: none;
}

.api-mamagment-button-remove.success .icon-remove .fa-check {
  display: flex;
  align-items: center;
  height: 40px;
}

.api-mamagment-button-remove:hover .icon-remove .fa-remove {
  height: 40px;
}

.api-mamagment-button-remove:active {
  opacity: 1;
}

.fa-remove:before {
   content: "\2716"
}

.fa-check:before {
   content: "\2714"
}
