.plan-main {
  margin: 32px 40px 40px 40px;
}

.plan-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.plan-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 8px;
}

.plan-item {
  display: block;
  padding: 0 8px 8px 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  text-decoration: none;
  transition: color 0.3s;
}

.plan-item:hover {
  color: #9D1B1F;
}

.plan-item--active {
  position: relative;
  color: #9D1B1F;
}

.plan-item--active:after {
  position: absolute;
  background-color: #9D1B1F;
  content: "";
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.plans-container {
  margin: 0 auto 32px;
}

.plans-container > h3 {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

/* @media (max-width: 1160px) {
  .plans-container {
    display: flex;
    flex-direction: column;
  }
} */

.plans-container {
  filter: drop-shadow(0px 1px 4px rgba(52, 50, 50, 0.12));
}

.plans-current {
  padding: 3px 10px;
  width: fit-content;
  background: #FBEDED;
  border-radius: 4px 4px 0px 0px;
}

.plans-current-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025;
}

.plans-current-title::before {
  content: url('../../images/icons/Curent Plan.svg');
  width: 10px;
  height: 10px;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.plans-available-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #D74A48;
  border-radius: 4px 4px 0px 0px;
}

.plans-available-name--2 {
  background: #B72025;
}

.plans-available-name--3 {
  background: #9D1B1F;
}

.plans-available-title::before {
  content: url('../../images/icons/Star Red.svg');
  width: 10px;
  height: 10px;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.plans-cards {
  display: grid;
  grid-template-columns: minmax(370px, 1fr) minmax(370px, 1fr) minmax(370px, 1fr);
  gap: 16px;
}

.plans-card {
  position: relative;
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  transition: transform .2s linear;
}

.availible-plan:hover {
  transform: scale(1.01)
}

.plans-subcard {
  padding: 24px;
}

.plans-card-current {
  display: grid;
  grid-template-columns: minmax(280px, 1fr) minmax(280px, 1fr) minmax(280px, 1fr);
  gap: 40px;
  margin-bottom: 36px;
  padding: 24px;
}

.plans-card-current-free {
  display: block;
  max-width: 550px;
}

.plans-card span {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.plans-card span > span {
  margin: 0;
  font-weight: 600;
  color: #a9a9a9;
}

.plans-card span > span > strong,
.plans-card span > strong {
  margin: 0;
  color: #343232;
}

.plans-card p,
.plans-card li {
  margin-bottom: 8px;
}

.plans-card li ul li {
  margin-top: 6px;
}

.plans-description-users,
.plans-description-billed,
.plans-expire {
  margin-bottom: 4px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.plans-description-users span,
.plans-description-billed span {
  font-weight: 600;
  color: #343232;
}

.plans-name-subscripton {
  margin-bottom: 8px;
}

.plans-name-subscripton-block {
  margin-bottom: 12px;
}

.plans-name {
  display: block;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  background: #F3F3F3;
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #343232;
}

.plans-name-pro {
  background: #B72025;
  color: #fff;
}

.plans-description li::before {
  content: url('../../images/icons/Check 2.svg');
  margin-right: 9px;
  margin-left: 5px;
}

.plans-last-item {
  margin-bottom: 16px !important;
}

.plans-expire-date {
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025 !important
}

.plans-pricing {
  display: flex;
  flex-direction: column;
}

.plans-pricing-current {
  position: absolute;
  right: 0;
  top: 58px;
  padding: 20px 12px;
  background: #FBEDED;
  border-radius: 4px 0px 0px 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025;
}

.plans-pricing p {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.plans-pricing-radio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.plans-pricing-radio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.plans-pricing-blocked {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 16px;
  margin-bottom: 16px;
  min-height: 76px;
  background: #F3F3F3;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.plans-pricing-blocked > span {
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.radio-button-disabled {
  opacity: 0.5;
}

.radio-button span {
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

input[type="radio"]:checked + .radio-ckecked {
  font-weight: 600;
  color: #343232;
}

.radio-button label {
  cursor: pointer;
}

.plans-pricing-price span:nth-of-type(1) {
  display: block;
  margin-right: 8px;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #B72025;
}

.plans-pricing-price span:nth-of-type(2) {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #B72025;
}

.plans-pricing-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.radio-button p {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.plans-subcard button {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #B72025;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.plans-subcard button > a {
  text-decoration: none;
  color: inherit;
}

.plans-subcard button:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
}
.plans-subcard button:not(:disabled):active {
  animation: gradient 150ms;
  transform: scale(0.99);
}

.plans-subcard button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.plans-subcard-users {
  margin-bottom: 20px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.plans-subcard-users::before {
  content: url('../../images/icons/User Circle.svg');
  position: relative;
  margin-right: 10px;
  top: 6px;
}

.plans-subcard p:nth-of-type(2) {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.plans-subcard p:nth-of-type(2):not(.plans-subcard-includes)::before {
  content: url('../../images/icons/Icon Plus.svg');
  position: relative;
  margin-right: 10px;
  top: 6px;
}

.plans-subcard .plans-subcard-includes::before {
  content: url('../../images/icons/Checked Circle.svg');
  position: relative;
  margin-right: 10px;
  top: 6px;
}

.plans-subcard .plans-description ul,
.plans-card li ul {
  list-style: disc;
  margin-left: 42px;
}

.plans-card li ul li::before {
  content: none;
}

.plans-description-second {
  margin-top: 30px;
}

.plans-description-includes {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.plans-subcard .plans-description ul li::before {
  content: none;
}

.plans-description-soon,
.plans-description-paused {
  margin-bottom: 16px;
  padding: 7px 16px;
  width: max-content;
  background: #FFF5E6;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FF9A01;
}

.plans-description-paused {
  margin-top: 16px;
}

.plans-description-paused span {
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.plans-description-expired {
  margin-bottom: 16px;
  padding: 7px 16px;
  width: max-content;
  background: #FDECED;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #EF444E;
}

.plans-description-expired-pro {
  margin-top: 16px;
}

.plans-description-upgrade {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.plans-description-upgrade span {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.plans-button-upgrade,
.plans-button-cancel,
.plans-button-renew {
  margin-top: 12px;
  margin-right: 12px;
  padding: 7px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  background: #FFFFFF;
  border-radius: 4px;
}

.plans-button-renew {
  margin-top: 0;
}

.plans-button-upgrade,
.plans-button-renew {
  border: 1px solid #B72025;
  color: #B72025;
  cursor: pointer;
  transition: all .2s linear;
}

.plans-button-upgrade:hover,
.plans-button-renew:hover {
  background: #B72025;
  color: #FFFFFF;
}

.plans-button-cancel {
  border: 1px solid #A4A3A3;
  color: #5D5B5B;
  cursor: pointer;
  transition: all .2s linear;
}

.plans-button-cancel:hover {
  color: #FFFFFF;
  background-color: #5D5B5B;
}

.plans-subcard .button-current-plan {
  background: #F3F3F3;
  border-color: #F3F3F3;
  color: #5D5B5B;
}

.seats-card {
  padding: 24px;
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.seats-card span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.seats-input{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}
 
.seats-input span {
  margin-right: 12px;
}

.seats-input input {
  margin-right: 5px !important;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.seats-input input::placeholder {
  font-size: 0.9em;
  color: #A4A3A3;
}

.seats-input input:hover {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.seats-input input:focus {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.seats-radio {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-controll {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
}

.seats-radio > span {
  margin-right: 112px;
}

.form-controll:focus-within {
  color: #B72025;
}

.card-title-modal {
  display: block;
  margin-bottom: 20px;
}

.modal {
  padding: 68px 32px 32px 32px;
  width: 530px;
}

.modal-cancel-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #343232;
}

.modal-cancel-subscription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.modal-cancel-subscription p {
  margin-bottom: 24px;
}

.modal-cancel-subscription a {
  color: #B72025;
}

.modal-cancel-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-gotit-button {
  display: flex;
  justify-content: center;
}

.modal-gotit-button button {
  padding: 7px 12px;
  background-color: #B72025;
  border: 1px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  transition: all .1s ease-out;
}

.modal-gotit-button button:hover {
  background-color: #FFFFFF;
  color: #B72025;
}

.modal-cancel-button button:first-of-type {
  padding: 7px 12px;
  background-color: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025;
  cursor: pointer;
  transition: all .1s ease-out;
}

.modal-cancel-button button:first-of-type:hover {
  background-color: #B72025;
  color: #FFFFFF;
}

.modal-cancel-button button:last-of-type {
  padding: 7px 12px;
  background-color: #B72025;
  border: 1px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  transition: all .1s ease-out;
}

.modal-cancel-button button:last-of-type:hover {
  background-color: #FFFFFF;
  color: #B72025;
}

.modal-close {
  border: none;
  background: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: all .1s ease-out;
  opacity: 0.6;
}

.modal-close:hover {
  opacity: 1;
}

.modal-close:active {
  transform: scale(0.9);
}

.modal-close::after {
  content: url('../../images/icons/Cross\ Grey.svg');
}

.disabled-item-monthly {
  position: absolute;
  left: 370px;
  width: 90px;
  height: 24px;
  z-index: 3;
  cursor: not-allowed;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  color: #C2C1C1;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid #C2C1C1;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
  cursor: pointer;
  transition: all 0.1s linear;
}

input[type="radio"]:not(:checked):hover {
  border-color: #5D5B5B;
}

input[type="radio"]:checked {
  border-color: #B72025;
}

input[type="radio"]::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #B72025;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
