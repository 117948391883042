.autologout-modal {
  padding: 60px;
  z-index: 11;
}

.autologout-modal-cancel-title {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  text-align: left;
}

.autologout-modal-gotit-button {
  display: flex;
  justify-content: center;
}

.autologout-modal-gotit-button button {
  padding: 8px 30px;
  background-color: #B72025;
  border: 1px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  transition: all .1s ease-out;
}

.autologout-modal-gotit-button button:hover {
  background-color: #FFFFFF;
  color: #B72025;
}

.autologout-modal-close {
  border: none;
  background: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: all .1s ease-out;
  opacity: 0.6;
}

.autologout-modal-close:hover {
  opacity: 1;
}

.autologout-modal-close:active {
  transform: scale(0.9);
}

.autologout-modal-close::after {
  content: url('../../images/icons/Cross\ Grey.svg');
}
