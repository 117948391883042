.sidebar {
  position: relative;
  min-height: calc(100vh - 64px);
  min-width: 240px;
  background: #FFFFFF;
}

.bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  height: calc(100vh - 190px);
  top: 64px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.bar-item-action {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  cursor: pointer;
}

.bar-item-action > img {
  position: absolute;
  right: 12px;
}

.bar-item {
  margin-left: 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.12em;
  color: #A4A3A3;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.item-title {
  height: 44px;
  color:#5D5B5B;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  justify-content: space-between;
}

.item-button {
  border: none;
  background-color: white;
  display: flex;
  justify-content: space-between;
  color: #5D5B5B;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  align-items: center;
}

.item-button:hover {
  cursor: pointer;
}

.bar-link {
  display: block;
  position: relative;
  min-width: 170px;
  padding: 8px 16px;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: none;
  text-decoration: none;
  border-radius: 4px;
}

.bar-link--beta::after {
  content: 'Beta version';
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 1px 4px;
  background-color: #B72025;
  opacity: .8;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.02em;
  border-radius: 4px;
  transition: opacity .1s ease-in-out;
}

.bar-link--beta--isActive::after {
  opacity: 1;
}

.bar-link-active {
  font-weight: 600;
  color: #343232;
  background: #FBEDED;
}

.bar-link-inactive {
  color: #5D5B5B;
  background: none;
  transition: color .3s;
}

.bar-link-inactive:hover {
  color: #9D1B1F;
}

.list-item {
  position: relative;
}

.ad-link-active::before,
.ad-link-inactive::before {
  position: relative;
  top: 3px;
  margin-right: 8px;
}

.ad-link-active::before {
  content: url('../../images/icons/Users 2.svg');
}

.ad-link-inactive::before {
  content: url('../../images/icons/Users 2.svg');
  opacity: 0.2;
}

.exc-link-active::before,
.exc-link-inactive::before {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  margin-right: 9px;
}

.exc-link-active::before {
  content: url('../../images/icons/Minus Circle.svg');
}

.exc-link-inactive::before {
  content: url('../../images/icons/Minus Circle.svg');
  opacity: 0.2;
}

.mandatory-link-active::before,
.mandatory-link-inactive::before {
  content: url('../../images/icons/Education.svg');
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  margin-right: 9px;
}

.mandatory-link-inactive::before {
  opacity: 0.2;
}

.groups-link-active::before,
.groups-link-inactive::before {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  margin-right: 9px;
}

.groups-link-active::before {
  content: url('../../images/icons/Groups.svg');
}

.groups-link-inactive::before {
  content: url('../../images/icons/Groups.svg');
  opacity: 0.2;
}

.sync-link-active::before,
.sync-link-inactive::before {
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
  margin-right: 9px;
}

.sync-link-active::before {
  content: url('../../images/icons/Sync 2.svg');
}

.sync-link-inactive::before {
  content: url('../../images/icons/Sync 2.svg');
  opacity: 0.2;
}

.act-link-active::before,
.act-link-inactive::before {
  padding-left: 3px;
  width: 10px;
  height: 12px;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

.lib-act-link-active::before,
.lib-act-link-inactive::before {
  padding-left: 3px;
  width: 10px;
  height: 12px;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

.act-link-active::before {
  content: url('../../images/icons/user-action.svg');
}

.act-link-inactive::before {
  content: url('../../images/icons/user-action.svg');
  opacity: 0.2;
}

.api-link-active::before,
.api-link-inactive::before {
  padding-left: 3px;
  width: 10px;
  height: 12px;
  position: relative;
  top: 3px;
  margin-right: 7px;
}

.api-link-active::before {
  content: url('../../images/icons/Group\ API.svg');
}

.api-link-inactive::before {
  content: url('../../images/icons/Group\ API.svg');
  opacity: 0.2;
}

.report-link-active::before,
.report-link-inactive::before {
  padding-left: 3px;
  width: 10px;
  height: 12px;
  position: relative;
  top: 1px;
  margin-right: 7px;
}

.report-link-active::before {
  content: url('../../images/icons/Group\ Phish.svg');
}

.report-link-inactive::before {
  content: url('../../images/icons/Group\ Phish.svg');
  opacity: 0.2;
}

.email-link-active::before,
.email-link-inactive::before {
  padding-left: 3px;
  width: 10px;
  height: 12px;
  position: relative;
  top: 2px;
  margin-right: 7px;
}

.email-link-active::before {
  content: url('../../images/icons/icon\ _cog_.svg');
}

.email-link-inactive::before {
  content: url('../../images/icons/icon\ _cog_.svg');
  opacity: 0.2;
}

.lib-act-link-active::before {
  content: url('../../images/icons/File.svg');
}

.lib-act-link-inactive::before {
  content: url('../../images/icons/File.svg');
  opacity: 0.2;
}

.history-link-active::before,
.history-link-inactive::before,
.analytics-link-active::before,
.analytics-link-inactive::before {
  width: 15px;
  height: 15px;
  position: relative;
  top: 3px;
  margin-right: 9px;
}

.history-link-active::before {
  content: url('../../images/icons/mhistory.svg');
}

.history-link-inactive::before {
  content: url('../../images/icons/mhistory.svg');
  opacity: 0.2;
}

.analytics-link-active::before {
  content: url('../../images/icons/graph.svg');
}

.analytics-link-inactive::before {
  content: url('../../images/icons/graph.svg');
  opacity: 0.2;
}

.sidebar-instruction {
  position: sticky;
  width: 100%;
  padding-left: 32px;
  left: 32px;
  top: calc(100vh - 110px);
}

.sidebar-instruction span {
  display: block;
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #343232;
}

.sidebar-instruction button {
  padding: 5px 12px 5px 30px;
  position: relative;
  background-color: #FFFFFF;
  border: 1.5px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.sidebar-instruction button::before {
  content: url('../../images/icons/Book Red.svg');
  position: absolute;
  left: 12px;
  top: 7px;
}

.sidebar-instruction button:hover {
  background-color: #B72025;
  color: #FFFFFF;
}

.sidebar-instruction button:hover::before {
  content: url('../../images/icons/Book White.svg');
}

.bar-report-notification {
  display: inline;
}

.bar-report-notification > div {
  display: inline-block;
  margin-left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #B72025;
  position: relative;
}

.more-than-9 {
  width: 25px !important;
}

.more-than-99 {
  width: 30px !important;
}

.more-than-999 {
  width: 33px !important;
}

.bar-report-notification span {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  transform: translateY(-2px);
}

.development-in-progress {
  opacity: 0.5;
}
