.csv-card {
  min-width: fit-content;
  padding: 32px 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.csv-card-details {
  margin-bottom: 24px;
}

.csv-card-title {
  margin-bottom: 16px;
}

.csv-card-title h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.csv-card-title p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.csv-card input::-webkit-file-upload-button {
  background-color: #FFFFFF;
  padding: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 12px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.csv-card input:disabled::-webkit-file-upload-button {
  cursor: not-allowed;
}

.csv-card input:not(:disabled)::-webkit-file-upload-button:hover {
  color: #FFFFFF;
  background-color: #5D5B5B;
}

.csv-card input[type=file] {
  position: relative;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.csv-form {
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  margin-bottom: 12px;
}

.csv-input-field {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.csv-input-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.csv-file-input {
  display: flex;
  align-items: flex-end;
}

.csv-file-input input:disabled {
  cursor: not-allowed;
}

.details-action-csv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.details-item-csv {
  min-height: 40px;
  /* min-width: 300px; */
  min-width: 250px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
}

.details-wide-item-csv {
  min-height: 40px;
  min-width: 400px;
  max-width: 400px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
  white-space: normal;
  word-wrap: break-word; 
  overflow: visible;
  text-overflow: clip;
}
