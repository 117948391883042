.sso-config-card {
  min-width: fit-content;
  padding: 24px;
  margin-bottom: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.sso-config-card > h3 {
  margin-bottom: 2px;
  color: #343232;
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.36px;
}

.sso-config-card > p {
  margin-bottom: 16px;
  color: #5D5B5B;
  font-size: 14px;
  font-family: Inter;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.sso-config-card > form {
  min-width: 410px;
}

.sso-config-button-set {
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #B72025;
  background-color: #FFF;
  color: #B72025;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  cursor: pointer;
  transition: .1s ease-in-out;
}

.sso-config-button-set:not(:disabled):hover {
  color: #FFF;
  background-color: #B72025;
}

.sso-config-button-set:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sso-config-label-span {
  color: #343232;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.sso-config-label-span::after {
  content: '*';
  margin-left: 4px;
  color: #DE2C37;
}

.sso-config-select {
  width: 410px;
  margin-bottom: 16px;
}

.sso-config-select > div {
  padding: 0;
}

.attach-button-container {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.attach-button,
.attach-button-manually {
  position: relative;
  padding: 7px 12px 7px 30px;
  background-color: #FFFFFF;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  transition: .1s ease-in-out;
}

.attach-button span,
.attach-button-manually span {
  color: #27ae60;
}

.attach-button::before {
  position: absolute;
  content: url('../../images/icons/attach.svg');
  left: 11px;
  top: 9px;
}

.attach-button-manually::before {
  position: absolute;
  content: url('../../images/icons/edit-button-grey.svg');
  left: 11px;
  top: 8px;
}

.attach-button:hover,
.attach-button-manually:hover {
  color: #343232;
  border-color: #343232;
}

.attach-button:hover::before {
  content: url('../../images/icons/attach black.svg');
}

.attach-button:active,
.attach-button-manually:active {
  transform: scale(0.9);
}

.attach-button-cross {
  margin-left: 6px;
  padding: 0;
  background-color: transparent;
  border: none;
  transform: translateY(4px);
  cursor: pointer;
  transition: .1s ease-in-out;
}

.attach-button-cross:before {
  content: url('../../images/icons/Cross rotate.svg');
}
.attach-button-cross:hover::before {
  content: url('../../images/icons/Cross rotate black.svg');
}

.sso-config-button-submit {
  position: relative;
  margin-right: 24px;
  padding: 12px 25px 12px 42px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.sso-config-button-submit::before {
  position: absolute;
  content: url('../../images/icons/Check.svg');
  top: 11px;
  left: 25px;
}
.sso-config-button-submit:not(:disabled):hover:before {
  content: url('../../images/icons/Check red normal.svg');
  margin-right: 5px;
}

.sso-config-button-submit:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.sso-config-button-submit:not(:disabled):hover {
  background-color: #FFFFFF;
  color: #B72025;
}

.sso-config-button-submit:not(:disabled):active {
  animation: gradient 150ms;
  transform: scale(0.99);
}

.sso-config-button-cancel {
  background-color: transparent;
  border: transparent;
  color: #5D5B5B;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  cursor: pointer;
  transition: .1s ease-in-out;
}

.sso-config-button-cancel:hover {
  color: #343232;
}

.sso-config-button-cancel::before {
  display: inline-block;
  margin-right: 4px;
  content: url('../../images/icons/Cross rotate.svg');
  transform: translateY(3px);
}

.sso-config-button-cancel:hover::before {
  content: url('../../images/icons/Cross rotate black.svg');
}

.sso-config-button-cancel:active {
  transform: scale(0.9);
}

.sso-config-details {
  display: grid;
  grid-template-columns: minmax(240px, 1fr) minmax(240px, 1fr) minmax(240px, 1fr);
  gap: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.sso-config-details span,
.sso-select p,
.attach-button-container div {
  color: #C2C1C1;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.sso-config-details p,
.sso-select-background {
  color: #343232;
  font-size: 14px;
  font-family: Inter;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.sso-select {
  margin-top: 20px;
}

.sso-select-background {
  position: relative;
  background-color: #FFFFFF;
  padding: 9px 48px 9px 12px;
  width: max-content;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
}

.sso-select-background::after {
  position: absolute;
  display: block;
  content: '';
  height: 80%;
  right: 38px;
  top: calc(0px + 10%);
  width: 1px;
  background-color: #D6D6D6;
  z-index: 1;
}
.sso-select-background > img {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: copy;
  opacity: 0.7;
  transition: opacity .1s ease-in-out;
}

.sso-select-background > img:hover {
  opacity: 1;
}

.form-sso-edit .card-title {
  display: block;
  margin-bottom: 0;
  color: #B72025;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.form-sso-edit .card-subtitle {
  display: block;
  margin-bottom: 10px;
  text-align: center;
  color: #343232;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.form-sso-edit {
  max-height: 80vh;
  overflow-y: scroll;
  min-width: 800px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;

  background: #FFFFFF;
  border-radius: 4px;
}

.content-sso-edit {
  width: 100%;
  margin-bottom: 16px;
}

.input-sso-edit {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 16px;

  width: 100%;
  height: 48px !important;

  border: 1px solid #D6D6D6 !important;
  border-radius: 4px;
}

.input-sso-edit::placeholder {
  font-size: 0.9em;
}

.input-sso-edit:not(:disabled):hover {
  box-shadow: 0 0 3px 0.5px #e7e6e6;
}

.input-sso-edit:focus {
  box-shadow: 0 0 3px 2px #e7e6e6;
  outline: none;
  border-color: #d3d3d3;
}

.input-field-sso {
  position: relative;
  margin-bottom: 12px;
  width: 100%;
}

.input-field-sso label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: 0.02em;
  color: #343232;
}

.eye-sso {
  position: absolute;
  width: 14px;
  height: 10;
  right: 14px;
  top: 40px;
  z-index: 99;
  cursor: pointer;
  opacity: 0.7;
}

.eye-sso:hover {
  opacity: 1;
}

.sso-card-action-modal {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.sso-card-action-modal button {
  width: 200px;
  height: 48px;
  border: 2px solid transparent;
  background-color: #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;

  transition: background-color .3s;
}

.sso-card-action-modal button:last-of-type {
  background-color: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  color: #B72025;
}

.sso-card-action-modal button:not(:disabled):hover {
  background-color: #9D1B1F !important;
}

.sso-card-action-modal button:last-of-type:hover {
  background-color: #9D1B1F;
  color: #FFFFFF;
}

.sso-card-action-modal button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.sso-card-action-modal button:not(:disabled):active {
  transform: translateY(2px);
}
