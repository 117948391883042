.history-card-analytics-mailing {
  width: 100%;
  min-width: 1200px;
  padding: 12px 24px 24px 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px #3432321f;
  border-radius: 4px;
}

.history-card-analytics-mailing .analytics-table-container {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  z-index: 6;
  background-color: #FFFFFF;
}

.history-card-analytics-mailing .analytics-unique-emails {
  color: #858484;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.history-card-analytics-mailing .analytics-select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.history-card-analytics-mailing .analytics-select-container-mailing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.analytics-all-users-table {
  position: relative;
  display: grid;
  border-collapse: collapse;
  min-width: 740px;
  width: 100%;
  grid-template-columns:
    minmax(130px, 1.5fr)
    minmax(210px, 1.5fr)
    minmax(180px, 2fr)
    minmax(145px, 2fr)
    120px
    155px
    130px
    40px;
  border: 1px solid #D6D6D6;
  border-top: none;
  border-radius: 4px;
}

.analytics-all-users-table .table-th-analytics {
  display: contents;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.analytics-all-users-table .table-th-analytics,
.analytics-all-users-table .table-body-analytics,
.analytics-all-users-table .tr-grid-analytics,
.analytics-all-users-table .tr-grid-analytics-all-users
 {
  display: contents;
}

.analytics-all-users-table .tr-grid-analytics-all-users-last {
  border-top: 1px solid #E6E6E6;
  border-bottom: none;
}

.analytics-all-users-table .tr-grid-analytics th,
.analytics-all-users-table .tr-grid-analytics td,
.analytics-all-users-table .tr-grid-analytics-all-users th,
.analytics-all-users-table .tr-grid-analytics-all-users td
{
  overflow: hidden;
  text-overflow: ellipsis;
}

.analytics-all-users-table .tr-grid-analytics td,
.analytics-all-users-table .tr-grid-analytics-all-users td {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 3px 12px;
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;

  border-bottom: 1px solid #E6E6E6;
}

.analytics-all-users-table .tr-grid-analytics-all-users td,
.analytics-all-users-table .tr-grid-analytics-all-users th {
  text-align: left;
  justify-content: start;
}

.analytics-all-users-table .tr-grid-analytics-all-users th {
  position: -webkit-sticky;
  position: sticky;
  top: 128px;
  z-index: 5;
}

.analytics-all-users-table .tr-grid-analytics-all-users td:nth-of-type(7),
.analytics-all-users-table .tr-grid-analytics-all-users th:nth-of-type(7)
{
  text-align: center;
  justify-content: center;
}

.analytics-all-users-table .tr-grid-analytics th,
.analytics-all-users-table .tr-grid-analytics-all-users th
{
  padding: 12px;
  text-align: left;
  background-color: #F3F3F3;
  border-bottom: 1px solid #E6E6E6;
  z-index: 4;
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.analytics-all-users-table .tr-grid-analytics-all-users th {
  border-top: 1px solid #D6D6D6;
}

.analytics-all-users-table .tr-grid-analytics:last-of-type td,
.analytics-all-users-table .tr-grid-analytics-all-users:last-of-type td {
  border-bottom: none;
}

.analytics-all-users-table .tr-grid-analytics-all-users:last-of-type .analytics-title-td {
  border-top: 1px solid #E6E6E6;
}

.analytics-all-users-table .tr-grid-analytics-all-users:last-of-type .analytics-title-td {
  border-top: 1px solid #E6E6E6;
}

.analytics-all-users-table .tr-analytics {
  background-color: #F3F3F3;
  width: fit-content;
}

.analytics-all-users-table .list-td-analytics {
  position: relative;
  color: #5D5B5B;
  transition: background-color .2s ease-in-out;
}

.analytics-all-users-table .list-td-analytics--open {
  background-color: #FBEDED;
  border-bottom: none !important;
}

.analytics-all-users-table .list-td-analytics--open,
.analytics-all-users-table .analytics-title-td--open,
.analytics-all-users-table .analytics-all-users-table-additional--open {
  z-index: 4;
  position: -webkit-sticky;
  position: sticky;
  top: 172px;
}

.analytics-all-users-table .list-td-analytics .alert-resend-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  transition: transform .2s ease-in-out;
}

.analytics-all-users-table .list-td-analytics .alert-resend-button:hover {
  transform: scale(1.3);
}

.analytics-all-users-table .list-td-analytics .alert-resend-button:active {
  transform: scale(1);
}

.analytics-all-users-table .table-option-analytics {
  position: absolute;
  display: flex;
  border: none;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #B72025;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: all .2s ease-in-out;
}

.analytics-all-users-table .table-option-analytics:hover,
.analytics-all-users-table .table-option-analytics--active {
  color: #FFFFFF;
  background-color: #B72025;
}

.analytics-all-users-table .table-option-analytics--active {
  bottom: -2px;
}

.analytics-all-users-table .analytics-list::after {
  margin-left: 4px;
  position: relative;
  background-image: url('../../../images/icons/arrow.svg');
  background-size: 10px 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  content:"";
}

.analytics-all-users-table .analytics-active-list::after {
  background-image: url('../../../images/icons/up-arrow.svg');
}

.analytics-all-users-table .analytics-title-td,
.analytics-all-users-table .analytics-all-users-table-additional {
  z-index: 3;
}

.analytics-all-users-table .analytics-title-td--open {
  top: 212px;
  background-color: #fff;
  border: 1px solid #D74A48 !important;
  border-bottom: 1px solid #D6D6D6 !important;
  border-radius: 4px 4px 0 0 !important;
  cursor: url('../../../images/icons/noun-close-tab.svg'), auto;
}

.analytics-all-users-table .analytics-all-users-table-additional--open {
  top: 252px;
}

.analytics-all-users-table .analytics-title-td span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  width: 100%;
  border-bottom: 1px solid #E6E6E6;
  color: #B72025;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  border: 0 !important;
}

.analytics-all-users-table .analytics-title-td {
  grid-column: 1 / -1;
  padding: 0;
}

.analytics-all-users-table .analytics-all-users-table-additional {
  grid-column: 1 / -1;
  border: 1px solid #D74A48 !important;
  border-top: none !important;
  border-radius: 0 0 4px 4px;
  background-color: #FBFBFB;
  padding: 0 !important;
}

.analytics-all-users-table .analytics-all-users-table-additional ul {
  width: 100%;
}

.analytics-all-users-table .analytics-li {
  display: grid;
  grid-template-columns:
    minmax(150px, 2fr)
    minmax(150px, 2fr)
    minmax(150px, 2fr);
  padding: 10px 20px;
  border-bottom: 1px solid #D6D6D6;
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px
}

.analytics-all-users-table .analytics-li span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.analytics-all-users-table .analytics-li:last-of-type {
  border: none;
}

.analytics-all-users-table .analytics-ul-title {
  font-weight: 600;
  padding: 10px 20px;
  border-bottom: 1px solid #D6D6D6;
  background-color: #F3F3F3;
}

.analytics-all-users-table .false-positive-anylytics {
  text-decoration: line-through;
}

.history-card-analytics-mailing .multiselect-action-type,
.history-card-analytics-mailing .email-type-select {
  width: 270px;
  min-width: 270px;
}

.history-card-analytics-mailing .action-type-select--disabled,
.history-card-analytics-mailing .email-type-select--disabled {
  opacity: 0.7;
  pointer-events: none;
  user-select: none;
};

.modal-container-resend-email .modal-title {
  margin-bottom: 30px;
}

.modal-container-resend-email .button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 30px;
  display: flex;
  height: 32px;
  padding: 7px 12px;
  border: 1px solid #B72025;
  background-color: #fff;
  border-radius: 4px;
  color: #B72025;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  cursor: pointer;

  transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.modal-container-resend-email .button path {
  transition: fill .2s ease-in-out;
}

.modal-container-resend-email .button:not(:disabled):hover {
  background-color: #B72025;
  color: #fff;
}

.modal-container-resend-email .modal-container-buttons .button:last-of-type {
  border-color: #858484;
  color: #858484;
}

.modal-container-resend-email .modal-container-buttons .button:last-of-type:not(:disabled):hover {
  background-color: #858484;
  color: #fff;
}

.modal-container-resend-email .button:not(:disabled):hover path {
  fill: #fff;
}

.modal-container-resend-email .button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.modal-container-resend-email .button:not(:disabled):active {
  transform: scale(0.99);
}

.modal-container-resend-email {
  position: relative;
  padding: 60px 40px 30px;
}

.modal-container-resend-email .modal-container-buttons {
  display: flex;
  gap: 20px;
}
