.categories-main {
  margin: 32px 40px 40px 40px;
}

.categories-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.categories-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 8px;
}

.categories-item {
  display: block;
  padding: 0 8px 8px 8px;
  min-width: fit-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  text-decoration: none;

  transition: color 0.3s;
}

.categories-item-summary {
  padding: 0 12px 8px 12px;
}

.categories-item:not(.categories-item--inactive):hover {
  color: #9D1B1F;
}

.categories-item--active {
  position: relative;
  color: #9D1B1F;
}

.categories-item--active:after {
  position: absolute;
  background-color: #9D1B1F;
  content: "";
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.categories-item--inactive {
  opacity: 0.5;
  cursor: not-allowed;
}

.category-main,
.category-main-compliance,
.categories-summary {
  min-width: 510px;
  width: 100%;
  padding: 24px;
  margin-bottom: 12px;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 4px #3432321f;
  border-radius: 4px;
}

.category-main-compliance-actions {
  display: flex;
  align-items: center;
  gap: 24px;
}

.category-main-name,
.category-main-name-compliance {
  margin: 0 0 8px 0;
  color: #343232;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.36px;
}

.category-main-description {
  color: #343232;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.category-main-description-compliance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.category-table {
  margin: 0 0 25px 0;
  display: grid;
  border-collapse: collapse;
  min-width: 740px;
  width: 100%;
  grid-template-columns:
    minmax(180px, 1.5fr)
    minmax(250px, 2fr)
    minmax(180px, 1fr)
    minmax(90px, 0.8fr)
    minmax(90px, 0.8fr)
    minmax(90px, 0.8fr)
    minmax(90px, 0.8fr)
    minmax(120px, 0.8fr)
    minmax(60px, 0.5fr);
  border: 1px solid #D6D6D6;
  border-radius: 4px;
}

.mandatory-users-table {
  border-top: none;
  grid-template-columns:
    minmax(40px, 0.2fr)
    minmax(130px, 1fr)
    minmax(130px, 2fr)
    minmax(110px, 1fr)
    minmax(100px, 1fr)
    minmax(120px, 1fr)
    minmax(134px, 1fr)
    minmax(125px, 1fr)
    minmax(90px, 0.5fr)
    minmax(140px, 0.5fr);
}

.mandatory-all-users-table {
  border-top: none;
  grid-template-columns:
    minmax(40px, 0.2fr)
    minmax(100px, 1fr)
    minmax(130px, 2fr)
    minmax(110px, 1fr)
    minmax(100px, 1fr)
    minmax(120px, 1fr)
    minmax(134px, 1fr);
}

.mandatory-users-table-additional {
  grid-column: 1 / -1;
  border: 1px solid #D74A48 !important;
  border-top: none !important;
  border-radius: 0 0 4px 4px;
  background-color: #FBFBFB;
  padding: 0 !important;
}

.mandatory-users-table-additional ul {
  width: 100%;
}

.tr-grid-mandatory-users-last {
  border-top: 1px solid #E6E6E6;
  border-bottom: none;
}

.table-th-category {
  display: contents;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.tr-grid-category th,
.tr-grid-category td,
.tr-grid-mandatory-users th,
.tr-grid-mandatory-users td 
{
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-td-category {
  position: relative;
  color: #5D5B5B;
  transition: background-color .1s ease-in-out;
}

.list-td-category--open {
  background-color: #FBEDED !important;
  border-bottom: none !important;
}

.list-td-category--open,
.mandatory-flow-title-td--open,
.mandatory-users-table-additional--open {
  z-index: 4 !important;
  position: -webkit-sticky;
  position: sticky;
  top: 176px;
}

.list-td-category--open {
  background-color: #fff;
}

.mandatory-flow-title-td--open {
  top: 216px;
  background-color: #fff;
  border: 1px solid #D74A48 !important;
  border-bottom: 1px solid #D6D6D6 !important;
  border-radius: 4px 4px 0 0 !important;
  cursor: url('../../images/icons/noun-close-tab.svg'), auto;
}

.mandatory-users-table-additional--open {
  top: 256px;
}

.list-td-category::before {
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.list-td-category-tenant-csv::before {
  content: url('../../images/icons/File\ Red.svg');
}
.list-td-category-tenant-google::before {
  content: url('../../images/icons/Google\ \(colors\).svg');
}
.list-td-category-tenant-azure::before {
  content: url('../../images/icons/Azure.svg');
}

.td-category-all-users {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #343232;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.td-category-groups {
  display: flex;
  align-items: center;
  margin-right: 6px;

  color: #858484;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.td-category-groups-container {
  display: flex;
  flex-wrap: wrap;
}

.td-category-groups-name {
  display: flex;
  white-space: nowrap;
  margin-right: 4px;
  height: 24px;
  min-width: fit-content;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-radius: 4px;
  background-color: #FBEDED;
  color: #B72025;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.list-td-status-configured,
.list-td-category .launched,
.list-td-status-fast-tack-compliance {
  display: flex;
  height: 24px;
  padding: 0px 10px 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: #2ED274;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.list-td-status-configured,
.list-td-status-fast-tack-compliance {
  background-color: #EAFAF1;
}

.list-td-status-configured::before,
.list-td-category .launched::before {
  position: relative;
  content: url('../../images/icons/Check\ Circle\ Green\ little.svg');
  top: 1.5px;
}

.list-td-status-not-defined,
.list-td-category .scheduled {
  display: flex;
  height: 24px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color:#5D5B5B;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.list-td-category .scheduled::before {
  position: relative;
  content: url('../../images/icons/icon\ _Clock_.svg');
  top: 1.5px;
}

.list-td-status-not-defined {
  background-color: #F3F3F3;
}

.list-td-category .launched,
.list-td-category .scheduled {
  padding: 0 5px;
}

.tr-grid-category td,
.tr-grid-mandatory-users td {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 3px 12px;
  color: #343232;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;

  border-bottom: 1px solid #E6E6E6;
}

.tr-grid-category .list-td-category-groups {
  flex-wrap: wrap;
}

.category-table-detailed .tr-grid-category td:last-of-type {
  padding-left: 0;
  border-left: 1px solid #E6E6E6;
}

.tr-grid-category td:last-of-type,
.tr-grid-category td:nth-of-type(4),
.tr-grid-category td:nth-of-type(5),
.tr-grid-category td:nth-of-type(6),
.tr-grid-category td:nth-of-type(7),
.tr-grid-category td:nth-of-type(8),
.tr-grid-category th:nth-of-type(4),
.tr-grid-category th:nth-of-type(5),
.tr-grid-category th:nth-of-type(6),
.tr-grid-category th:nth-of-type(7),
.tr-grid-category th:nth-of-type(8),
.tr-grid-category th:last-of-type {
  text-align: center;
  justify-content: center;
}

.tr-grid-mandatory-users td,
.tr-grid-mandatory-users th {
  text-align: left;
  justify-content: start;
}

.tr-grid-mandatory-users th {
  position: -webkit-sticky;
  position: sticky;
  top: 132px;
  z-index: 5 !important;
}

.tr-grid-mandatory-users td:nth-of-type(7),
.tr-grid-mandatory-users th:nth-of-type(7),
.tr-grid-mandatory-users td:nth-of-type(8),
.tr-grid-mandatory-users th:nth-of-type(8),
.tr-grid-mandatory-users td:nth-of-type(9),
.tr-grid-mandatory-users th:nth-of-type(9)
{
  text-align: center;
  justify-content: center;
}

.tr-grid-mandatory-users td:nth-of-type(10),
.tr-grid-mandatory-users th:nth-of-type(10) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tr-grid-category th,
.tr-grid-mandatory-users th
{
  padding: 12px;
  text-align: left;
  background-color: #F3F3F3;
  border-bottom: 1px solid #E6E6E6;
  z-index: 3;
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.tr-grid-mandatory-users th {
  border-top: 1px solid #D6D6D6;
}

.table-th-category,
.table-body-category,
.tr-grid-category,
.tr-grid-mandatory-users
 {
  display: contents;
}

.tr-category {
  background-color: #F3F3F3;
  width: fit-content;
}

.list-td-category i {
  display: flex;
  height: 32px;
  padding: 4px 9px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #A4A3A3;
  background-color: #FFF;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.list-td-category i:hover {
  border-color: #9D1B1F;
}

.list-td-category i::after {
  display: block;
  position: relative;
  top: 2px;
  content: url('../../images/icons/icon\ _Pen_.svg');
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.list-td-category i:hover::after {
  content: url('../../images/icons/icon\ _Pen_Red.svg');
}

.list-td-category--low-compliance,
.list-td-category--moderate-compliance,
.list-td-category--moderate-compliance {
  font-weight: 600;
  margin: 0 1px;
}

.list-td-category--low-compliance {
  color: #DE2C37;
}

.list-td-category--moderate-compliance {
  color: #ED8F00;
}

.list-td-category--high-compliance {
  color: #28B865;
}

.not-clickable-category button {
  margin: 0;
  padding: 0;
  border: none;
  background-color: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.not-clickable-category button:not(:disabled):hover {
  color: #9D1B1F
}

.not-clickable-category button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.not-clickable-category button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px
}

.categories-tr th:first-of-type,
.categories-th th:last-of-type,
.categories-tr td:first-of-type,
.categories-th td:last-of-type {
  text-align: center !important;
}

.tr-grid-category:last-of-type td, 
.tr-grid-mandatory-users:last-of-type td {
  border-bottom: none;
}

.category-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px 32px 32px;

  background-color: #FFF;
  border-radius: 4px;
}

.category-content {
  margin-bottom: 12px;
  min-width: 610px;
}

.category-form .card-title {
  display: block;
  margin-bottom: 8px;
  text-align: left;
  color: #343232;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.category-form .card-subtitle {
  display: block;
  margin-bottom: 24px;
  text-align: left;
  color: #A4A3A3;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.category-form .card-subtitle strong {
  color: #343232;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.category-input {
  min-width: 500px;
  display: flex;
  position: relative;
}

.category-input::before {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 30px;
  left: 13px;
  content: url('../../images/icons/Groups.svg');
  opacity: 0.8;
}

.category-input-label p {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.category-select-label,
.category-input-label,
.category-select-label--1 {
  color: #343232;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.category-select-label {
  width: 100%;
}

.category-input-label > span {
  color: #ff0008;
}

.category-select-label--1 {
  font-weight: 600;
}

.category-select-label--1 strong {
  font-size: 15px;
}

.category-select-label:not(.category-select-label--1) {
  margin-bottom: 4px;
}

.category-card-action-modal {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.category-card-action-modal button {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 2px solid transparent;
  background-color: #B72025;
  border-radius: 4px;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  cursor: pointer;

  transition: background-color .3s;
}

.category-card-action-modal button:first-of-type:before {
  content: url('../../images/icons/Check-white-small.svg');
  position: relative;
  top: 3px;
}

.category-card-action-modal button:last-of-type {
  background-color: #FFF;
  border: 1px solid #B72025;
  transition: all 0.2s;
  color: #B72025;
}

.category-card-action-modal button:not(:disabled):hover {
  background-color: #9D1B1F !important;
}

.category-card-action-modal button:last-of-type:hover {
  background-color: #9D1B1F;
  color: #FFFFFF;
}

.category-card-action-modal button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.category-card-action-modal button:not(:disabled):active {
  transform: translateY(2px);
}

.category-input-disabled {
  pointer-events: none;
  opacity: 0.5;
  user-select: none;
}

.card-content-all-users,
.card-content-fastrack-compliance {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.card-content-fastrack-compliance {
  margin-bottom: 12px;
}

.card-content-fastrack-compliance:last-of-type {
  margin-bottom: 8px;
}

.category-input-or {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: #C2C1C1;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.category-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 12px;
}

.category-input-container div {
  display: none;
}

.input-edit-category {
  display: flex;
  width: 140px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  background-color: #FFF;
  color: #343232;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.28px;

  transition: all .1s ease-in-out;
}

.select-edit-category,
.select-edit-category:disabled {
  min-width: 200px;
  min-height: 40px;
  background-color: #FFF;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-edit-category::placeholder {
  font-size: 0.9em;
  color: #A4A3A3;
}

.input-edit-category:not(:disabled):hover {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.input-edit-category:focus {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.mandatory-flow-list::after {
  margin-left: 4px;
  position: relative;
  background-image: url('../../images/icons/up-arrow.svg');
  background-size: 10px 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  content:"";
}

.mandatory-flow-active-list::after {
  background-image: url('../../images/icons/arrow.svg');
}

.mandatory-flow-li {
  display: grid;
  grid-template-columns:
  minmax(150px, 2fr)
  minmax(150px, 2fr)
  minmax(150px, 2fr)
  minmax(100px, 1fr);
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  border-bottom: 1px solid #D6D6D6;
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px
}

.mandatory-flow-li:last-of-type {
  border: none;
}

.mandatory-flow-title-td span { 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  width: 100%;
  border-bottom: 1px solid #E6E6E6;
  color: #B72025;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  border: 0 !important;
}

.mandatory-flow-title-td {
  grid-column: 1 / -1;
  padding: 0 !important;
}

.mandatory-flow-ul-title {
  font-weight: 600;
  padding: 12px 20px;
  border-bottom: 1px solid #D6D6D6;
  background-color: #F3F3F3;
}

.tr-grid-mandatory-users:last-of-type .mandatory-flow-title-td {
  border-top: 1px solid #E6E6E6;
}

.mandatory-flow-resend-button {
  padding: 6px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: transparent;
  cursor: pointer;
  transition: opacity 0.1s;
}

.mandatory-flow-resend-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.mandatory-table-tenant {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 20px;
  padding: 16px 0;
}

.mandatory-table-tenant p {
  color: #5d5b5b;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .02em;
  line-height: 16px;
}

.input-field-mandatory-tenant {
  position: relative;
  z-index: 6;
}

.input-field-mandatory-tenant::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px !important;
  left: 14px !important;
  content: url('../../images/icons/Layer Group.svg');
  z-index: 7;
}

.select-edit-mandatory {
  min-width: 400px;
  min-height: 40px;
  background-color: #FFFFFF;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  z-index: 6;
}

.all-users-select-mandatory {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  background-color: #fff;
  padding: 16px 2px 12px 2px;
  z-index: 6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.all-users-select-mandatory-additional {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.userslist-select-label-mandatory {
  position: relative;
  transition: opacity .2s ease-in-out;
}

.userslist-select-label-mandatory:before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 13px;
  content: url('../../images/icons/Search.svg');
}

.userslist-select-label-mandatory--disabled {
  display: none;
}

.userslist-select-label-mandatory-additional {
  position: relative;
}

.all-users-select-mandatory p {
  color: #343232;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.mandatory-flow-title-td,
.mandatory-users-table-additional {
  z-index: 3;
}

.table-option-mandatory {
  position: absolute;
  display: flex;
  border: none;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s linear;
  text-align: center;
}

.table-option-mandatory-sent-email {
  border: none;
  background: transparent;
  width: fit-content;
  height: fit-content;
}

.table-option-mandatory-sent-email:not(:disabled):active {
  transform: translatex(3px);
}

.table-option-mandatory-sent-email:disabled svg {
  fill: #D6D6D6
}

.table-option-mandatory-sent-email svg {
  fill: #D74A48;
  position: relative;
  width: 35px;
  height: 15px;
  top: 3px;
  transform: translateX(-15px);
  transition: fill .2s ease-in-out;
}

.table-option-mandatory-sent-email:not(:disabled) svg:hover {
  fill: #9D1B1F;
  cursor: pointer;
}

.tr-grid-mandatory-users .table-option-mandatory-sent-email-batch {
  position: absolute;
  padding: 3px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid #A4A3A3;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  border-radius: 4px;
  transition: border-color .2s ease-in-out, color .2s ease-in-out, transform .1s ease-in-out;
}

.tr-grid-mandatory-users .table-option-mandatory-sent-email-batch:not(:disabled):hover {
  border-color: #9D1B1F;
  color: #9D1B1F;
}
.tr-grid-mandatory-users .table-option-mandatory-sent-email-batch:not(:disabled):active {
  transform: translateY(2px);
}

.tr-grid-mandatory-users .table-option-mandatory-sent-email-batch:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.tr-grid-mandatory-users .table-option-mandatory-sent-email-batch:not(:disabled):hover svg {
  fill: #9D1B1F;
}

.table-option-mandatory-sent-email-batch svg {
  fill: #5D5B5B;
  width: 30px;
  height: 22px;
  transition: fill .2s ease-in-out;
}

.table-option-mandatory:hover,
.table-option-mandatory--active {
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
}

.table-option-mandatory--active {
  transform: translateY(6px);
}

.mini-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
}

.mini-loader-container--summary {
  height: 36px;
}

.mini-loader-send-email {
  position: relative;
  left: -8px;
  width: 18px;
  height: 18px;
  border: 3px solid #ccc;
  border-bottom-color: #B72025;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.advaced-fasttrack-settings {
  margin: 0 0 8px 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: #343232;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.advaced-fasttrack-settings-checkbox {
  position: relative;
  margin: 0;
  width: 14px;
  height: 14px;
  accent-color: #B72025;
  cursor: pointer;
  top: -1px;
}

.categories-summary-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categories-summary-details-chart {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categories-summary-additional-container {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  margin-bottom: 12px;
  gap: 12px;
  width: 100%;
}

.categories-summary-additional-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 24px 24px 24px;
  background-color: #fff;
  box-shadow: 0px 1px 4px #3432321f;
  border-radius: 4px;
}

.categories-summary-additional-stats h3 {
  text-align: center;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  color: #646262;
}

.categories-summary-additional-container .categories-summary-additional-stats--graph {
  height: 100%;
  display: grid;
  align-content: center;
}

.categories-summary-additional-container .stats-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.categories-summary-additional-container .stat {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.categories-summary-additional-container .stat-percentage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.categories-summary-additional-container .progress-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
}

.categories-summary-additional-container .progress-container--first {
  margin-bottom: 3rem;
}

.categories-summary-additional-container .progress-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
}

.categories-summary-additional-container .progress-bar {
  background-color: #E0E0E0;
  border-radius: 25px;
  width: 100%;
  height: 37px;
  overflow: hidden;
  position: relative;
  box-shadow: #C2C1C1 0px 9px 18px, #C2C1C1 0px 6px 8px;
}

.categories-summary-additional-container .progress {
  background: #28B865;
  height: 100%;
  border-radius: 25px;
  transition: width 800ms, background-color 800ms;
}

.categories-summary-additional-container .progress--non-compliant {
  background-color: #28B865;
}

.categories-summary-additional-container .progress--non-compliant--moderate {
  background-color: #ed8f00;
}
.categories-summary-additional-container .progress--non-compliant--high {
  background: linear-gradient(90deg, #ed8f00 0%, #de2c37 30%);
  animation: appear 500ms ease-in-out;
}

.categories-summary-additional-container .progress-text {
  display: flex;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 14px;
  color: #343232;
}

.categories-summary-additional-container .progress-text .progress-text--animated {
  margin-right: 4px;
}

.categories-summary-additional-container .stats-container .stat .stat-percentage {
  flex-direction: row;
  font-size: 40px;
  font-weight: 500;
  color: #28B865;
  animation: appear 500ms ease-in-out;
}
.categories-summary-additional-container .stats-container .stat .stat-percentage--non-compliant {
  color: #28B865;
  transition: color 800ms ease-in-out;
}
.categories-summary-additional-container .stats-container .stat .stat-percentage--non-compliant--moderate {
  color: #ed8f00;
  transition: color 800ms ease-in-out;
}
.categories-summary-additional-container .stats-container .stat .stat-percentage--non-compliant--high {
  color: #DE2C37;
  transition: color 800ms ease-in-out;
}

.categories-summary-additional-container .stats-container .stat-percentage-desc {
  font-size: 14px;
  color: #858484;
  font-weight: 600;
}

.chart-container-mandatory-training-pie {
  position: relative;
}

.chart-metric-mandatory-training {
  transition: .3s ease-in-out;
  height: 100%;
  padding: 10px 0 24px;
  background-color: #fff;
  box-shadow: 0px 1px 4px #3432321f;
  border-radius: 4px;
}

.chart-metric-mandatory-training-download,
.categories-summary-additional-stats-download {
  opacity: .7;
  pointer-events: none;
  transition: .3s ease-in-out;
}

.input-container-mandatory-training-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.input-container-mandatory-training-item--disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.input-field-mandatory-training-category {
  position: relative;
  text-align: left;
  width: 100%;
}

.input-field-mandatory-training-category .react-select__control {
  box-shadow: #d1d1d1 0px 9px 18px, #d1d1d1 0px 6px 8px;
  border-radius: 25px;
  transition: box-shadow .2s ease-in-out;
}
.input-field-mandatory-training-category .react-select__control:hover {
  box-shadow: #C2C1C1 0px 9px 18px, #C2C1C1 0px 6px 8px;
  cursor: pointer;
}

.input-field-mandatory-training-category .react-select__value-container {
  font-weight: bold;
  font-size: 14px;
  color: #343232;
  text-align : center;
}

.input-field-mandatory-training-category .react-select__single-value:before {
  content: url('../../images/icons/Education-3.svg');
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.input-field-mandatory-training-category .category-select--all .react-select__single-value:before {
  content: url('../../images/icons/all-2.svg');
}

.tr-grid-mandatory-users th {
  display: flex;
  align-items: center;
}
