.user-analytics-input-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  padding: 10px;
  background: #FBFBFB;
  border: 1px solid transparent;
  z-index: 5;
  border-radius: 0 0 10px 10px;
  transition: top 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
}

.user-analytics-input-container--show {
  position: sticky;
  top: -4px;
  border: 1px solid #ffffff36;
  box-shadow: 0 1px 2px #0000001a;
}

.user-analytics-input-container--fixed {
  top: 63px;
  background: #ffffff91;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
}

.user-analytics-scroll-button {
  visibility: hidden;
  position: absolute;
  right: 0;
  bottom: -28px;
  z-index: 4;
  color: #B72025;
  padding: 0 8px 2px 8px;
  cursor: pointer;

  background: #ffffff1a;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  border: 1px solid #ffffff36;
  border-top: none;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px #0000001a;
  opacity: 0.8;
  transition: top 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out,
    opacity 0.1s ease-in-out;
  animation: appear 0.3s ease-in-out;
}

.user-analytics-scroll-button--show {
  visibility: visible;
}

.user-analytics-scroll-button:hover {
  box-shadow: 0 2px 5px #0000001a;
  opacity: 1;
}

.input-container-analytics-item--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.input-container-select-analytics-item {
  z-index: 5;
}

.input-container-select-analytics-item .react-select__control {
  height: 44px;
  width: max-content;
  width: 190px;
  padding: 0 10px 0 14px;
  border: transparent;
  border-radius: 20px;
  background: #F4F4F4;
}

.input-container-select-analytics-item .react-select__control:hover {
  border: transparent;
  cursor: pointer;
}

.input-container-select-analytics-item .react-select__value-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;
}

.input-container-select-analytics-item .react-select__value-container,
.input-container-select-analytics-item .react-select__placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.input-container-select-analytics-item .react-select__single-value {
  color: #9D1B1F;
}

.input-container-select-analytics-item .react-select__input-container {
  margin: 0;
}

.input-container-select-analytics-item .react-select__indicator-separator {
  display: none;
}

.input-container-select-analytics-item .react-select__dropdown-indicator svg {
  fill: #000;
}

.input-container-select-analytics-item .react-select__clear-indicator {
  padding: 0;
}

.input-container-select-analytics-item .react-select__dropdown-indicator {
  padding: 0 3px;
}

.input-container-select-analytics-item .react-select__clear-indicator svg {
  fill: #9D1B1F;
  opacity: 0.6;
  transition: opacity .2s ease-in-out;
}

.input-container-select-analytics-item .react-select__clear-indicator svg:hover {
  opacity: 0.9;
}

.input-container-select-analytics-item .react-select__value-container::before {
  position: relative;
  margin-right: 6px;
}

.input-container-select-analytics-item--tenant .react-select__value-container::before {
  content: url('../../../images/icons/layer_group_black.svg');
  top: 1px;
}

.input-container-select-analytics-item--department .react-select__value-container::before {
  content: url('../../../images/icons/department_groups.svg');
  top: 2px;
}

.input-container-select-analytics-item--location .react-select__value-container::before {
  content: url('../../../images/icons/location_on.svg');
  top: 1px;
}

.input-container-select-analytics-item--date-range .react-select__value-container::before {
  content: url('../../../images/icons/calendar_month.svg');
  top: 1px;
}

.input-container-select-analytics-item .react-select__menu {
  background: #FBFBFB;
  overflow: hidden;
  border-radius: 10px;
}

.input-container-select-analytics-item .react-select__clear-indicator:disabled {
  display: block;
}

.user-analytics-input-container .analytics-date-range-container {
  margin: 0;
  display: flex;
  width: max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  animation: appear 0.4s ease-in-out;
}

.user-analytics-input-container .analytics-date-range-container .react-datepicker-popper {
  z-index: 3;
}

.user-analytics-input-container .input-edit-history {
  padding: 14px 14px 14px 20px;
  border: transparent;
  border-radius: 20px;
  background: #F4F4F4;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #9D1B1F;
}

.user-analytics-input-container .react-datepicker {
  border: 1px solid #D6D6D6;
  border-radius: 10px;
}

.user-analytics-input-container .react-datepicker-wrapper input {
  padding: 14px 16px;
  display: block;
  width: 145px;
  text-align: right;
}

.user-analytics-input-container .input-field-history::before {
  position: absolute;
  left: 14px;
  top: 12px;
  z-index: 2;
}

.user-analytics-input-container .input-field-history:first-of-type::before {
  content: url('../../../images/icons/Calendar-arrow-right.svg');
}

.user-analytics-input-container .input-field-history:last-of-type::before {
  content: url('../../../images/icons/Calendar-arrow-left.svg');
}

.generate-report-button {
  padding: 9px 20px;
  display: flex;
  justify-content: center;
  min-width: 190px;
  height: 37px;
  border: 1px solid #C42D28;
  border-radius: 100px;
  background-color: #C42D28;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  animation: appear .2s ease-in-out;
}

.generate-report-button svg {
  position: relative;
  margin-right: 6px;
}

.generate-report-button:hover {
  background-color: #FFF;
  color: #C42D28;
}

.generate-report-button svg path {
  transition: fill .2s ease-in-out;
}

.generate-report-button:hover svg path {
  fill: #C42D28;
}

.generate-report-button:active {
  transform: translateY(2px);
}

.analytics-report-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 11px 10px 0;
}

.analytics-report-button .generate-report-button {
  padding: 10px;
}

.analytics-report-button .csv-download-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 190px;
  border-color: #C42D28;
  border-radius: 100px;
  height: 37px;
  background-color: #F4F4F4;
  opacity: 0.5;
  pointer-events: none;
}

.analytics-report-button .csv-download-loading span {
  position: relative;
  top: -0.5px;
}
