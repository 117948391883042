.header {
  min-width: 100%;
  width: fit-content;
  height: 64px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #B72025;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.header-beta {
  position: absolute;
  left: 280px;
}

.header-beta span {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.nav-logo-card {
  width: 240px;
  height: 64px;
  background: #9D1B1F;
}

.nav-logo {
  margin: 6px auto;
  width: 104px;
  height: 51px;
}

.navbar {
  position: relative;
  display: flex;
  min-width: 230px;
  flex-direction: row;
  justify-content: flex-end;
}

.navbar-email {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
}

.navbar-user {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.navbar-profile {
  position: relative;
  margin-right: 8px;
  background: #fff;
  border: 1px solid #E6E6E6;
  border-radius: 50%;
  overflow: hidden;
  width: 34px;
  height: 34px;
  top: 2px;
  left: 4px;
}

.navbar-profile img {
  position: absolute;
  top: 49%;
  left: 49%;
  transform: translate(-50%, -50%);
}

.navbar-profile .navbar-profile-img {
  top: 50%;
  left: 50%;
}

.nav-current-plan {
  padding: 4px 10px;
  width: max-content;
  background: #FBFBFB;
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.nav-current-plan-free {
  color: #5D5B5B;
}

.nav-current-plan-small-b {
  color: #B72025;
}

.nav-current-plan-pro {
  color: #B72025;
}

.nav-current-plan-enterprise {
  color: #B72025;
}

.nav-current-plan-expired {
  color: #EF444E;
}

.nav-current-plan-paused::before,
.nav-current-plan-expired::before {
  content: url('../..//images/icons/Alert\ triangle\ small.svg');
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.nav-current-profile {
  margin-left: 8px;
}

.nav-list {
  position: absolute;
  background: #FFFFFF;
  width: 100%;
  top: 54px;
  display: flex;
  flex-direction: column;
  padding: 4px;
  box-shadow: 0px 2px 8px rgba(52, 50, 50, 0.15);
  border-radius: 4px;
}

.nav-link {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 8px 15px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  border-radius: 4px;
  transition: all .3s;
}

.nav-link--admin {
  color: #B72025;
}

.nav-link:hover {
  background: #FBEDED;
}

.nav-link-img {
  position: relative;
  margin-right: 6px;
  top: 4px;
  opacity: 0.7;
}

.nav-link-img--1 {
  width: 10px;
  height: 14px;
  margin-right: 11px;
}

.nav-link-img--2 {
  width: 12px;
  height: 14px;
  margin-right: 9px;
}

.nav-link-img--3 {
  width: 14px;
  height: 11px;
  top: 5px;
  margin-right: 7px;
}

.nav-link-img--4 {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.nav-link-img--5 {
  width: 14px;
  height: 13px;
  top: 4px;
  margin-right: 7px;
}

.nav-link-img--6 {
  width: 14px;
  height: 14px;
  top: 4px;
  left: 2px;
  margin-right: 7px;
}

.button-arrow {
  border: none;
  background: #B72025;
  padding-left: 10px;
  margin-top: 19px;
  cursor: pointer;
}

.not-owner-item--disabled {
  display: none;
}
