.user-analytics-card {
  min-width: 1200px;
  width: 100%;
  padding: 10px 10px 0 10px;
}

.user-analytics-card--found {
  margin-bottom: 20px;
}

.user-analytics-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.user-analytics-select-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  animation: appear 0.4s ease-in-out;
}

.user-analytics-card h3 {
  display: block;
  min-width: 270px;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input-container-select-analytics-item--user .react-select__control {
  width: 320px;
}

.user-select-analytics .react-select__control {
  width: max-content;
  min-width: 320px;
  max-width: 360px;
}

.input-container-select-analytics-item--user .react-select__value-container::before {
  content: url('../../../images/icons/User_Black.svg');
  top: 1px;
}

.user-analytics-select-container .analytics-date-range-container {
  margin: 0;
  display: flex;
  width: max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  animation: appear 0.4s ease-in-out;
}

.user-analytics-select-container .analytics-date-range-container .react-datepicker-popper {
  z-index: 3;
}

.user-analytics-select-container .react-datepicker-wrapper input {
  padding: 14px 16px;
  display: block;
  width: 145px;
  text-align: right;
}

.user-analytics-select-container .input-edit-history {
  padding: 14px 14px 14px 20px;
  border: transparent;
  border-radius: 20px;
  background: #F4F4F4;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #9D1B1F;
}

.user-analytics-select-container .input-field-history::before {
  position: absolute;
  left: 14px;
  top: 12px;
  z-index: 2;
}

.user-analytics-select-container .input-field-history:first-of-type::before {
  content: url('../../../images/icons/Calendar-arrow-right.svg');
}

.user-analytics-select-container  .input-field-history:last-of-type::before {
  content: url('../../../images/icons/Calendar-arrow-left.svg');
}

.user-analytics-select-container .metrics-loader-container {
  width: 320px;
  justify-content: center;
}

.user-analytics-raw {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
  animation: appear 0.2s ease-in-out;
}

.user-analytics-raw--loading {
  margin-bottom: 40px;
}

.analytics-metrics-user-report-card {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background: #F4F4F4;
}

.analytics-metrics-user-report-card h4 {
  margin-bottom: 15px;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.user-analytics-raw .analytics-metrics-item-container {
  padding-right: 10px;
  min-width: 160px;
}

.user-analytics-raw .analytics-metrics-item:first-of-type .analytics-metrics-item-container {
  border: none;
  padding: 0;
}

.user-report-mini-card {
  padding: 20px;
  width: max-content;
  min-width: 300px;
  max-width: 340px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
  overflow-x: auto;
}

.user-report-mini-card .metrics-loader-container {
  height: 22px;
  margin-bottom: 12px;
}

.user-analytics-text-item-first--name .metrics-loader-container {
  height: 30px;
  margin-bottom: 16px;
}

.user-report-text-item-title-first {
  min-width: 260px;
  color: #5D5B5B;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.user-report-text-item-value-first {
  color: #C42D28;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.4px;
  padding-bottom: 16px;
  animation: appear 300ms ease-in-out;
}

.user-report-text-item-value-second {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  padding-bottom: 12px;
  animation: appear 300ms ease-in-out;
}

.user-report-text-item-value-second--last {
  padding: 0;
}

.user-report-text-item-value-second--copy {
  cursor: copy;
}

.analytics-metrics-value .analytics-metrics-value-current--low,
.analytics-metrics-value .analytics-metrics-value-current--compliant {
  color: #0000FF;
}

.analytics-metrics-value .analytics-metrics-value-current--high,
.analytics-metrics-value .analytics-metrics-value-current--non-compliant {
  color: #B72025;
}

.analytics-metrics-value .analytics-metrics-value-current--moderate {
  color: #ED8F00;
}

.compliance-table .prone-tables-grid .table-container .no-data-available,
.compliance-table .prone-tables-grid {
  margin-bottom: 20px;
}
