.trend-graph-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  background: linear-gradient(to right bottom, #f7f8ec, #fdf5ed, #fdf1f0, #faf0f5, #efe8fc);
  border-radius: 10px;
  transition: opacity .3s ease-in-out;
}

.trend-graph-container--loading {
  opacity: 0.7;
}

.trend-graph-container h4 {
  margin-bottom: 5px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.trend-graph-container .trend-graph-curr-value {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.trend-graph-container .trend-graph-curr-value span:first-of-type {
  margin-right: 10px;
  display: block;
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  animation: appear 300ms ease-in-out;
}

.trend-graph-value-prev {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  animation: appear 300ms ease-in-out;
}

.trend-graph-value-prev--positive {
  color: #28b865;
}

.trend-graph-value-prev--negative {
  color: #de2c37;
}

.trend-graph-line {
  margin-bottom: 15px;
}

.trend-graph-bar {
  margin-bottom: 15px;
}

.trend-graph-dates {
  display: flex;
  justify-content: flex-end;
}

.trend-graph-dates p {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  animation: appear 300ms ease-in-out;
}

.trend-graph-slider {
  position: relative;
  margin: 0 14px 10px;
  animation: appear 0.3s ease-in-out;
}

.trend-graph-slider-mark {
  position: absolute;
  top: -20px;
  font-size: 16px;
  color: #4ca3e4;
  animation: appear 0.3s ease-in-out;
  visibility: hidden;
  user-select: none;
}

.trend-graph-slider-mark--show {
  visibility: visible;
  transition: visibility 0.5s ease-in-out, left 0.5s ease-in-out;
}

.trend-graph-slider span {
  font-size: 12px;
}

.trend-graph-slider-track {
  margin-bottom: 10px;
}

.trend-graph-slider-track .rc-slider-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  opacity: 1;
  box-shadow: 0 2px 5px #0000001a;
  transition: box-shadow 0.1s ease-in-out;
}

.trend-graph-slider-track .rc-slider-handle::before {
  content: '◄►';
  color: #fff;
  font-size: 12px;
}

.trend-graph-container .rc-slider-handle:active {
  box-shadow: none;
}

.trend-graph-container .trend-graph-slider-track .rc-slider-handle.rc-slider-handle-dragging:active {
  box-shadow: 0 0 2px 5px #0000001a;
}

.trend-graph-slider .item-highlited {
  color: #4ca3e4;
}

.trend-graph-slider--additional {
  display: flex;
  justify-content: space-between;
}
