.pagination-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  user-select: none;
}

.pagination-container ul {
  display: flex;
  list-style-type: none;
  padding: 6px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px #3432321f;
  border-radius: 4px;
}

.pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  margin: auto 2px;
  height: 32px;
  color: #5D5B5B;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 32px;
  transition: background-color .1s ease-in-out;
}


.pagination-item--disabled {
  pointer-events: none;
  opacity: 0.4;
  display: none;
}

.pagination-item--disabled:hover {
  cursor: default;
}

.pagination-item:not(.pagination-item--selected):hover {
  background-color: #FBEDED;
  cursor: pointer;
}

.pagination-item--selected {
  font-weight: 700;
  font-size: 15px;
  color: #343232;
  background-color: #ffdede;
  cursor: default;
}

.pagination-item .pagination-arrow::before {
  position: relative;
  content: url('../../images/icons/icon _chevron-left_.svg');
}

.pagination-arrow--left {
  transform: translateY(2px);
}

.pagination-arrow--right {
  transform: rotate(180deg) translateY(2px);
}

.pagination-item.dots {
  pointer-events: none;
}
