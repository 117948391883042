@import "./normalize.css";
@import "./animation.css";
@import "../App.css";

body:has(.page)::before,
body:has(.page)::after {
  content: '';
  display: block;
  position: fixed;
  height: 64px;
  width: calc((100vw - 1920px) / 2);
  background: linear-gradient(to left, #9D1B1F, #B72025);
  z-index: 1;
}

body::after {
  top: 0;
  right: 0;
}

.toast-message-error,
.toast-message-success,
.toast-message-info {
  right: 20px;
  background: #fff !important;
  box-shadow: 0px 1px 4px #3432321f !important;
}

.toast-message-warning {
  padding: 24px !important;
  top: 40vh !important;
  background: #FBEDED !important;
  border: 1px solid #FFB84D;
  box-shadow: 3px 5px 7px #3432321f !important;
}

.toast-message-error {
  top: 50px;
}

.Toastify__close-button {
  align-self: center !important;
}

.scroll-to-top {
  background: none !important;
  box-shadow: none !important;
  bottom: 53px !important;
  right: 55px !important;
  opacity: 0.6;
  transition: opacity 0.4s,
    animation 0.4s,
    transform 0.4s;
  animation: transform 0.7s infinite ease-in-out;
}

.scroll-to-top:hover {
  animation: paused;
  transform: scale(1.05);
  opacity: 0.9
}

.unauthorized {
  display: block;
  position: relative;
  background: radial-gradient(39.53% 75.94% at 96.25% 89.53%,#8C0A0A 0%, rgba(102, 8, 8, 0) 100%),
    radial-gradient(55.78% 112.5% at 0% 0%, #B10505 0%, rgba(135, 2, 2, 0) 94.47%), #580507;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.page {
  min-height: 100vh;
  background: #F5F5F5;
  min-width: fit-content;
}

.main {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: calc(100vh - 64px);
  z-index: 0;
  box-shadow: 0px 1px 4px 0px #3432321f;
}

.component {
  width: 100%;
  height: 100%;
}

.card {
  padding: 30px;
}

.logo {
  margin: 0 auto 30px;
  width: 200px;
  height: 100px;
}

.log-out {
  display: inline;
  color: rgb(2, 2, 160)
}

.button-link {
  color: aliceblue;
}

.table {
  margin-bottom: 25px;
}

.item--disabled:not(.us-database-item--active) {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.disabled-item {
  position: absolute;
  width: 200px;
  height: 40px;
  z-index: 3;
  cursor: not-allowed;
}

.react-datepicker-wrapper input {
  width: 100px;
}

.react-datepicker {
  font-family: 'Inter', sans-serif;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  background-color: #fff;
}

.react-datepicker__header {
  background-color: #F5F5F5;
  border-bottom: 1px solid #e2e2e2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.react-datepicker__day {
  color: #343232;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: transparent;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  border-radius: 50%;
  color: #343232;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #B72025;
  color: #fff;
}

.react-datepicker__day:hover {
  background-color: #EFB7B6;
}

.react-datepicker__day--selected:hover {
  background-color: #B72025;
  color: #fff;
}

.react-datepicker__day--outside-month {
  color: #858484;
}

.react-datepicker__day--disabled {
  color: #e2e2e2;
  cursor: not-allowed;
}

.react-datepicker__day--disabled:hover {
  background-color: #fff;
  color: #e2e2e2;
}
