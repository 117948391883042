.plans-table-card {
  min-width: fit-content;
  margin: 0 0 32px 0;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.plans-table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.plans-table {
  border-collapse: collapse;
  width: 100%;
}

.table-th-plans {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
}

.not-clickable-plans th {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
}

.clickable-row-plans {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: center;
}

.tr-grid-plans td {
  padding: 7px 0;
  margin: 0 auto;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.tr-grid-plans td ul {
  list-style: disc;
  margin-left: 20px;
}

.tr-grid-plans td:first-of-type {
  padding: 9px 0 9px 16px;
  text-align: left;
}

.tr-grid-plans td:first-of-type:not(:has(br))::after,
.tr-grid-plans br {
  content: url('../../images/icons/Info Circle.svg');
  position: relative;
  top: 4px;
  margin-left: 4px;
}

.tr-grid-plans th {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  padding: 9px 0;
  text-align: center;
  background: #fff;
  z-index: 3;
}

.tr-grid-plans th:first-of-type {
  padding: 9px 0 9px 16px;
  text-align: left;
}

.tr-grid-plans th:nth-of-type(3) {
  color: #D74A48;
}

.tr-grid-plans th:nth-of-type(4) {
  color: #B72025;
}

.tr-grid-plans th:nth-of-type(5) {
  color: #9D1B1F;
}

.tr-grid-plans img {
  display: inline;
}

.tr-plans td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.tr-grid-plans-button {
  text-align: center;
}

.table-body-plans button {
  margin-top: 10px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #B72025;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
}
