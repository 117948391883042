.main-title-phish {
  margin: 32px 0px 16px 40px;
}

.details-main-phish {
  margin: 32px 40px 40px 40px;
}

.details-main-title-phish {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.details-title-phish {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.details-card-phish {
  min-width: fit-content;
  display: flex;
  justify-content: space-between;
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.details-account-list-phish {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 32px; */
}

.details-action-phish,
.details-action-ad-phish {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.details-action-phish,
.details-action-google-phish {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.details-action-phish button {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #B72025;
  padding: 12px 16px 12px 32px;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  transition: background-color 0.1s linear;
}

.details-action-phish button::before {
  content: url('../../images/icons/Plus Red.svg');
  position: absolute;
  left: 16px;
  top: 12px;
}

.details-action-phish button:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
  cursor: pointer;
}

.details-action-phish button:not(:disabled):hover::before {
  content: url('../../images/icons/Plus.svg');
}

.details-action-title-phish {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.details-action-subtitle-phish {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;

  color: #5D5B5B;
}

.details-list-phish {
  display: grid;
  grid-template-columns: minmax(240px, 1fr) minmax(240px, 1fr) minmax(240px, 1fr);
  gap: 32px;
}

.details-item-phish {
  min-height: 40px;
  /* min-width: 300px; */
  min-width: 250px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
}

.details-item-button-phish {
  min-width: fit-content;
}

.details-value-phish {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.details-value-role-phish::first-letter {
  text-transform: capitalize;
}

.details-value-company-phish {
  font-weight: 700;
  font-size: 14px;
}

.button-link-phish img {
  display: inline;
  margin-right: 6px;
  width: 12px;
  height: 12px;
}

.button-link-phish button {
  width: 76px;
  height: 40px;

  border: 1px solid #A4A3A3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
}

.button-link-phish button:hover {
  border: 2px solid #A4A3A3;
}

.account-details-card-phish,
.account-details-card-users-phish {
  padding: 32px 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.account-details-card-phish {
  margin-bottom: 24px;
}

.account-details-table-phish {
  border-collapse: collapse;
  width: 100%;
}

.table-th-account-details-phish {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
}

.not-clickable-account-details-phish th,
.not-clickable-account-details-details-phish th {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}

.account-details-table-phish td,
.account-details-table-phish tr {
  z-index: 2;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
}

.tr-grid-account-details-phish th,
.tr-grid-account-details-phish td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tr-grid-account-details-phish td {
  padding: 7px 0 7px 12px;
  cursor: pointer;
}

.tr-grid-account-details-phish th {
  position: -webkit-sticky;
  position: sticky;
  top: 63px;
  padding: 11px 0 11px 12px;
  text-align: left;
  background: #fff;
  z-index: 3;
}

.tr-account-details-phish {
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 0;
}

.tr-account-details-phish td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.table-body-account-details-phish td:last-of-type::first-letter {
  text-transform: capitalize;
}

.table-body-account-details-phish td span {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #9D1B1F;
}

.card-action-modal-phish button {
  width: 404px;
  height: 48px;
  border: 2px solid transparent;
  background-color: #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;

  transition: background-color .3s;
}

.card-action-modal-phish button:hover {
  background-color: #9D1B1F;
}

.card-action-modal-phish button:active {
  background-color: #B72025;
}
