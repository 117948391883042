.tr-actions td {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.false-positive-action td {
  text-decoration: line-through;
}

.tr-actions th:last-of-type {
  margin: 0;
}

.tr-actions {
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 0;
}

.actions-card {
  min-width: 1200px;
  width: 100%;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.actions-description {
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.actions-action {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.actions-open {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.actions-action-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
  color: #343232;
}

.actions-table {
  margin: 16px 0 0 0;
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns:
    minmax(150px, 2fr)
    minmax(150px, 4fr)
    minmax(150px, 3fr)
    minmax(150px, 3fr)
    minmax(150px, 3fr)
    minmax(170px, 2fr)
    minmax(110px, 1.5fr)
    minmax(130px, 1fr);
}

.table-th-actions,
.table-body-actions,
.tr-grid-actions {
  display: contents;
}

.tr-grid-actions th,
.tr-grid-actions td {
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.tr-grid-actions td {
  padding: 7px 0 7px 12px;
}

.tr-grid-actions th {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  padding: 11px 0 11px 12px;
  text-align: left;
  background: #fff;
}

.not-clickable-actions th {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}

.clickable-row-actions td {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  transition: background-color .2s;
}

.actionnavbar {
  transition: transform 0.1s;
}

.actionnavbar:hover {
  transform: scale(1.03)
}

.chart-container-actions {
  max-width: 96%;
  margin: 0 auto 16px;
}

.input-container-user-actions-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-container-user-actions-title h3 {
  margin-right: 20px;
}

.input-container-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.input-container-actions p {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.input-field-actions, .input-field-actions-tenant {
  position: relative;
}

.input-field-actions-close--date,
.input-field-actions-close--number {
  position: absolute;
  height: 36px;
  width: 100px;
}

.input-field-actions-tenant::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 9px;
  left: 14px;
  content: url('../../images/icons/Layer Group.svg');
  z-index: 2;
}

.input-field-actions label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: 0.02em;
  color: #343232;
}

.input-edit-actions {
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.select-edit-actions {
  min-width: 200px;
  min-height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-edit-actions::placeholder {
  font-size: 0.9em;
  color: #A4A3A3;
}

.input-edit-actions:not(:disabled):hover {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.input-edit-actions:focus {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.actions-download {
  width: 130px;
  height: 32px;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
}

.actions-download:hover {
  border: 2px solid #A4A3A3;
}

.actions-download::after {
  width: 9px;
  height: 12px;
  position: relative;
  top: 2px;
  margin-left: 7px;
  content: url('../../images/icons/export.svg');
}
.actions-download:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.actions-table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.actions-table-tenant {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 10px;
}

.actions-table-tenant p {
  color: #5d5b5b;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .02em;
  line-height: 16px;
}

.actions-main {
  margin: 32px 40px 40px 40px;
}

.actions-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.actions-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 8px;
}

.actions-item {
  display: block;
  padding: 0 8px 8px 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  text-decoration: none;

  transition: color 0.3s;
}

.actions-item:hover {
  color: #9D1B1F;
}

.actions-item--active {
  position: relative;
  color: #9D1B1F;
}

.actions-item--active:after {
  position: absolute;
  background-color: #9D1B1F;
  content: "";
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.table-th-actions button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.table-th-actions button:hover {
  color: #9D1B1F
}

.table-th-actions button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.table-th-actions button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.sum-metric {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 96%;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  transition: .3s ease-in-out;
}

.sum-metric-item {
  padding-right: 20px;
}

.sum-metric-label {
  font-size: 12px;
  color: #A4A3A3;
}

.sum-metric-value {
  font-size: 20px;
  color: #343232;transition: .3s ease-in-out;
}

.sum-metric-value-download,
.chart-metric-download {
  opacity: .5;
  pointer-events: none;
  transition: .3s ease-in-out;
}

.chart-metric {
  transition: .3s ease-in-out;
}

.actions-chart-loading,
.input-edit-actions-loading {
  cursor: wait;
}

.multiselect-user {
  min-width: 320px;
  min-height: 40px;
}
.multiselect-action-type {
  min-width: 300px;
  min-height: 40px;
}

.action-type-select {
  position: relative;
}

.action-type-select:before {
  display: block;
  width: 14px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 13px;
  content: url('../../images/icons/noun-actions.svg');
}

.th-false-positive {
  z-index: 3;
}

.th-false-positive,
.td-false-positive {
  display: flex;
  align-items: center;
  justify-content: center;
}
