.group-main {
  margin: 32px 40px 40px 40px;
}

.group-main-title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.groups-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 8px;
  width: 100%;
  overflow: hidden;
}
.scroll-button {
  border: none;
  height: min-content;
  background-color: transparent;
cursor: pointer;
}
.scroll-button:hover {
  transform: scale(1.2);
}

.logo-group {
  width: 25px;
  height: 25px;
  display: inline-block;
}

.groups-item {
  display: block;
  padding: 0 8px 8px 8px;
  min-width: 15%;
  height: min-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  text-decoration: none;
text-align: center;
  transition: color 0.3s;
}

.groups-item:not(.groups-item--inactive):hover {
  color: #9D1B1F;
}

.groups-item--active {
  position: relative;
  color: #9D1B1F;
}

.groups-item--active:after {
  position: absolute;
  background-color: #9D1B1F;
  content: '';
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.groups-item--inactive {
  opacity: 0.5;
  cursor: not-allowed;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.group-button-link {
  text-decoration: none;
}

.group-button-link button {
  display: flex;
  align-items: center;
  width: max-content;
  height: 40px;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  padding: 12px 16px;
}

.group-button-link img {
  display: inline;
  margin-right: 6px;
  width: 24px;
  height: 24px;
}

.group-button-link button:hover {
  background-color: #9D1B1F;
  color: white
}

.groups-back-link {
  position: relative;
  display: block;
  max-width: max-content;
  margin: 11px 0;
  text-decoration: none;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025;
  transition: transform .1s linear;
}

.groups-back-link::before {
  content: url('../../images/icons/Arrow\ Circle\ Left\ Red.svg');
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.groups-back-link:hover {
  transform: translateX(-3px);
}

.group-buttons-container {
  display: flex;
  gap: 10px;
}

.excl-button {
  border: none;
  background-color: white;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  align-items: center;
  padding: 0;
}

.excl-list-title{
  display: flex;
  gap: 4px;
  margin: 0;
}

.groups-list-title {
  font-weight: 400;
}

.excl-list {
  margin-bottom: 8px;
  background-color: #E6E6E6;
}

.filter-link {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.filter-link a,
.filter-link button {
  position: relative;
  color: #B72025;
  font-weight: 600;
  text-decoration: none;
  border: none;
  background-color: #FFFFFF;
  margin-top: 4px;
}

.filter-link a:hover,
.filter-link button:hover {
  position: relative;
  color: #B72025;
  font-weight: 600;
  text-decoration-line: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  cursor: pointer;
}

.delete-link {
  color: #B72025;
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;
  border: none;
  background-color: #FFFFFF;
  cursor: pointer;
}

.delete-link:hover {
  color: #B72025;
  font-weight: 600;
  text-decoration-line: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
}
