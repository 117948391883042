.segments-add-select {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.tr-grid-spu {
  display: grid;
  grid-template-columns: 3fr 4fr 4fr 1fr;
  padding: 9px 0 9px 12px;
  width: 100%;
  min-height: 40px;
  text-align: left;
}

.tr-grid-spu th:last-of-type {
  margin: 0 auto;
}

.segments-add-and {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.users-select {
  position: relative;
  display: inline;
  margin: 0;
  padding: 0;
}

.users-select::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 8px;
  left: 13px;
  content: url('../../../images/icons/Add user.svg');
}

.segments-select {
  position: relative;
}

.segments-select::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px;
  left: 13px;
  content: url('../../../images/icons/Layer Group.svg');
}

.segments-label::before {
  width: 10px;
  height: 10px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  content: url('../../../images/icons/Layer Group Small.svg');
}

.caption {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}
