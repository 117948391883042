.history-main--version {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.history-main--version button {
  border: none;
  background-color: transparent;
  padding: 5px;
  width: max-content;
  height: max-content;
  color: #5D5B5B;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  align-items: center;
  cursor: pointer;
  transition: color .2s ease-in-out;
}

.history-main--version button:hover {
  color: #B72025;
}

.input-container-history-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-container-history-actions-item .option-select-default,
.input-container-history-actions-item .multiselect-user {
  width: 270px;
  min-width: 270px;
}

.input-container-history-actions p {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.input-container-history-actions-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.history-card-mailing h3 {
  margin-bottom: 14px;
}

.history-card-mailing,
.history-card-chart,
.actions-card-chart {
  position: relative;
  padding: 24px;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px #3432321f;
  border-radius: 4px;
}

.history-card-chart,
.actions-card-chart {
  padding: 24px 0 24px 24px;
}

.history-card-chart h3,
.actions-card-chart h3 {
  position: absolute;
  top: 24px;
  left: 24px;
  color: #343232;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.36px;
}

.metric-container-mailing {
  margin: 0;
}

.history-card-chart-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
}

.history-card-chart,
.actions-card-chart {
  display: grid;
  grid-template-columns: 1fr 4fr;
  height: 100%;
}

.history-card-chart .sum-metric,
.actions-card-chart .sum-metric {
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  width: max-content;
  margin: 0;
  gap: 10px;
}

.history-card-chart .sum-metric-item,
.actions-card-chart .sum-metric-item {
  padding: 0;
  width: 120px;
}

.history-card-chart .sum-metric-value,
.actions-card-chart .sum-metric-value {
  font-size: 16px;
  line-height: 16px;
}

.chart-container-history-pie {
  position: relative;
  width: 90%;
}

.sum-metric-value-download,
.chart-metric-actions-download,
.chart-metric-mailing-download {
  opacity: .5;
  pointer-events: none;
  transition: .3s ease-in-out;
}

.chart-metric-actions,
.chart-metric-mailing {
  transition: .3s ease-in-out;
  height: 100%;
}

.date-range-select-label {
  display: block;
  width: 175px;
}

.chart-container-history-pie .no-data-text {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
  color: #888888;
  user-select: none;
}
