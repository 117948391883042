.page:has(.analytics-main) {
  background: #F4F1F1;
}

.analytics-main {
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  background: #FBFBFB;
}

.analytics-main-title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.analytics-nav {
  margin-bottom: 10px;
  display: inline-flex;
  padding: 5px;
  align-items: flex-start;
  border-radius: 50px;
  background: #F4F4F4;
}

.analytics-item {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.analytics-item--active {
  border-radius: 50px;
  background-color: #FFF;
  color: #C42D28;
  font-weight: 600;
}

.analytics-item:hover {
  color: #9D1B1F;
}

.user-analytics-card-summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}

.trend-graphs-container {
  display: flex;
  flex: 1 1 100%;
  gap: 20px;
}

.trend-graphs-container:not(:last-of-type) {
  margin-bottom: 20px;
}

.trend-graphs-container.trend-graphs-container--loading {
  margin-bottom: 40px;
}

.trend-graphs-container-item-table {
  width: 50%;
}

.trend-graphs-container .trend-graphs-container-item,
.trend-graphs-container-item-table {
  flex-grow: 0;
  flex-basis: calc(50% - 10px);
  transition: height 300ms ease-in-out;
}

.analytics-metrics {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: #F4F4F4;
}

.analytics-metrics h4 {
  margin-bottom: 15px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

.analytics-metrics-container-risk {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.8fr;
}

.analytics-metrics-container-education {
  display: grid;
  grid-template-columns: 1fr 1fr 0.8fr;
}

.executive-input-field-analytics,
.executive-input-field-analytics-department,
.executive-input-field-analytics-location,
.executive-input-field-analytics-tenant {
  position: relative;
  z-index: 7;
}
