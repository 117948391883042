.sys-admin-card {
  min-width: 710px;
  width: 100%;
  padding: 24px;
  margin-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.sys-admin-title {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.sys-admin-card h4 {
  padding: 10px 0;
}

.sys-admin-subtitle {
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;

  color: #5D5B5B;
}

.sys-admin-card .divider {
  margin: 20px 0;
  height: 2px;
  width: 320px;
  border-radius: 4px;
}

.sys-admin-select {
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
}

.sys-admin-select--disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.sys-admin-select .email-template-select,
.sys-admin-select .segment-select,
.sys-admin-select .email-type-select,
.sys-admin-select .tenant-select,
.sys-admin-select .users-select,
.sys-admin-select .company-select,
.sys-admin-select .risk-level-select,
.sys-admin-select .department-select,
.sys-admin-select .location-select {
  display: block;
  width: 320px;
}

.sys-admin-select .tenant-select {
  margin-bottom: 0;
}

.sys-admin-select .users-select {
  z-index: 0;
}

.sys-admin-select .email-template-select:before,
.sys-admin-select .segment-select:before,
.sys-admin-select .email-type-select:before,
.sys-admin-select .tenant-select:before,
.sys-admin-select .company-select:before,
.sys-admin-select .users-select:before,
.sys-admin-select .risk-level-select:before,
.sys-admin-select .department:before,
.sys-admin-select .location:before {
  width: 18px;
  height: 20px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.sys-admin-select .email-template-select:before {
  content: url('../../images/icons/Email\ template.svg');
}

.sys-admin-select .segment-select:before {
  content: url('../../images/icons/Layer\ Group.svg');
}

.sys-admin-select .company-select:before {
  content: url('../../images/icons/company.svg');
}

.sys-admin-select .risk-level-select:before {
  content: url('../../images/icons/levels.svg');
}

.sys-admin-select .users-select:before,
.sys-admin-select .department-select:before,
.sys-admin-select .location-select:before {
  top: 12px;
}

.sys-admin-card .card-content-all-users {
  margin-bottom: 8px;
}

.sys-admin-modal-container {
  position: relative;
  padding: 60px 40px 30px;
  max-width: 640px;
}

.sys-admin-modal-container .modal-title {
  margin-bottom: 30px;
  color: #B72025;
}

.sys-admin-modal-button-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.sys-admin-modal-container .button,
.sys-admin-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100px;
  margin-top: 40px;
  height: 32px;
  padding: 7px 12px;
  border: 1px solid #B72025;
  background-color: #fff;
  border-radius: 4px;
  color: #B72025;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  cursor: pointer;

  transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.sys-admin-modal-container .button .mini-loader-send-email {
  left: 0;
}

.sys-admin-card .sys-admin-btn {
  margin-top: 10px;
}

.sys-admin-modal-container .button:not(:disabled, .button--cancel):hover,
.sys-admin-btn:not(:disabled):hover {
  background-color: #B72025;
  color: #fff;
}

.sys-admin-modal-container .button--cancel {
  border-color: #A4A3A3;
  color: #A4A3A3;
}

.sys-admin-modal-container .button--cancel:hover {
  background-color: #A4A3A3;
  color: #fff;
}

.sys-admin-modal-container .button:disabled,
.sys-admin-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.sys-admin-modal-container .button:not(:disabled):active,
.sys-admin-btn:not(:disabled):active {
  transform: scale(0.99);
}

.sys-admin-modal-container ul {
  padding: 5px;
}

.sys-admin-modal-container p,
.sys-admin-modal-container li {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.sys-admin-card .slider {
  z-index: 0;
}

.sys-admin-card .slider-checkbox-label {
  color: #343232;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}
