.modal-form {
  padding: 24px;
  max-height: 80vh;
  /* overflow: scroll; */
}


.modal-title {
  margin-bottom: 12px;
  font-size: 20px;
  text-align: left;
  color: #343232;
}

.modal-content {
  width: 100%;
  padding: 0 8px;
}

.modal-select {
  width: 100%;
  margin-bottom: 12px;
}

.modal-users-list {
  margin-bottom: 20px;
}

.modal-users-list-scroll {
  max-height: 320px;
  overflow-y: scroll;
}


.modal-button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

