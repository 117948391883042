.analytics-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.analytics-action {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.analytics-open {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.analytics-action-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
  color: #343232;
}

.chart-container-analytics {
  max-width: 96%;
  margin: 0 auto 16px;
}

.input-container-analytics-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-container-analytics-title h3 {
  padding-bottom: 16px;
}

.input-container-analytics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.input-container-old-analytics-item {
  display: flex;
  align-items: center;
  gap: 20px;
}

.input-container-old-analytics-item--disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.input-container-analytics p {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.input-field-analytics,
.breach-input-field-analytics,
.executive-input-field-analytics,
.executive-input-field-analytics-department,
.executive-input-field-analytics-location,
.executive-input-field-analytics-tenant {
  position: relative;
  z-index: 7;
}

.input-field-analytics label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: 0.02em;
  color: #343232;
}

.input-edit-analytics {
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-edit-analytics::placeholder {
  font-size: 0.9em;
  color: #A4A3A3;
}

.input-edit-analytics:hover {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.input-edit-analytics:focus {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.select-edit-analytics {
  min-width: 200px;
  min-height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.old-analytics-main {
  margin: 32px 40px 40px 40px;
}

.old-analytics-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.old-analytics-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 8px;
}

.old-analytics-item {
  display: block;
  padding: 0 8px 8px 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  text-decoration: none;
}

.old-analytics-item:hover {
  color: #9D1B1F;
}

.old-analytics-item--active {
  position: relative;
  color: #9D1B1F;
}

.old-analytics-item--active:after {
  position: absolute;
  background-color: #9D1B1F;
  content: "";
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.old-user-analytics-card-summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}


.summary-checkbox-container,
.summary-checkbox-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.summary-checkbox-container {
  gap: 30px;
}

.summary-checkbox-label label {
  margin-right: 5px;
}

.summary-checkbox {
  width: 14px;
  height: 14px;
  accent-color: #B72025;
  cursor: pointer;
}

.user-analytics-card--loading {
  cursor: progress;
}

.user-analytics-mini-card {
  min-width: 35%;
  width: 100%;
  padding: 24px;
  margin-right: 12px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.user-analytics-mini-card:last-child{
  margin-right: 0;
  min-width: 20%;
}

.user-analytics-mini-card h3{
  padding-bottom: 16px;
}

.user-analytics-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.user-analytics-title h3 {
  padding-bottom: 16px;
}

.user-analytics-title-first h3 {
  padding: 16px 0;
}

.risk-graph-container {
  max-height: 500px; 
  overflow-y: scroll;
}

.user-analytics-text-item {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 12px;
  color: var(--neutral-90, #343232);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.phishing-text-item-value {
  color: var(--negative-80, #DE2C37);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.education-text-item-value {
  color: var(--success-80, #28B865);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.user-report-text-item-value {
  color: var(--neutral-90, #343232);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.user-report-text-item-title {
  color: var(--neutral-70, #858484);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.user-analytics-input {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.user-analytics-input p,
.summary-checkbox-label,
.summary-checkbox-label label {
  color: #343232;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.36px;
}

.summary-checkbox-label,
.summary-checkbox-label label {
  cursor: pointer;
}

.summary-checkbox-label label {
  font-size: 12px;
}

.summary-checkbox-label--disabled,
.summary-checkbox-label--disabled label,
.summary-checkbox-label--disabled input[type="checkbox"] {
  cursor: not-allowed;
  opacity: 0.7;
  user-select: none;
}

.user-report-title {
  padding-bottom: 16px;
}

.user-report-input {
  display: flex;
  position: relative;
}

.user-report-mini-card-first {
  min-width: 35%;
  width: 100%;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.users-report-label::before {
  width: 11px;
  height: 8px;
  position: relative;
  top: 1px;
  margin-right: 5px;
  content: url('../../images/icons/Envelope Small.svg');
}

.user-report-input::before {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 13px;
  content: url('../../images/icons/User 1.svg');
}

.breach-input-field-analytics::before, .executive-input-field-analytics::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px;
  left: 14px;
  content: url('../../images/icons/Layer Group.svg');
  z-index: 2;
}

.user-report-mini-card-title{ 
  margin-bottom: 18px;
}

.user-analytics-text-first {
  color: var(--neutral-80, #5D5B5B);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.no-risk-users {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: green;
}
.Moderate_Risk {
  color: orange;
  font-weight: 600;
}

.High_Risk {
  color: red;
  font-weight: 600;
}
.Low_Risk {
  color: green;
  font-weight: 600;
}

.user-select {
  margin-bottom: 12px;
  min-height: 40px;
  min-width: 500px;
}

.user-select--disabled {
  opacity: 0.7;
}

.analytics-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.analytics-input-container .user-select {
  margin: 0;
}

.analytics-date-range-container {
  display: flex;
  width: max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-right: 10px;
}

.analytics-date-range-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.education-text-item-title-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
