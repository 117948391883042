.tenants-main {
  min-width: 510px;
  width: 100%;
  padding: 24px;
  margin-bottom: 12px;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.tenants-main-name {
  margin: 0;
  color: #343232;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.36px;
}

.tenants-main-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.tenants-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.input-edit-tenants {
  position: relative;
  width: max-content;
}

.input-edit-tenants > input {
  width: 300px;
  padding: 6px 30px 6px 30px;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  background-color: #FFF;
  color: #343232;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.28px;

  transition: all .1s ease-in-out;
}

.input-edit-tenants::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 8px;
  left: 10px;
  content: url('../../images/icons/Search.svg');
}

.input-edit-tenants > input::placeholder {
  font-size: 0.9em;
  color: #A4A3A3;
}

.input-edit-tenants > input:not(:disabled):not(:focus):hover {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.input-edit-tenants > input:focus {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.input-edit-tenants > i {
  display: inline-block;
  position: absolute;
  right: 0;
  width: 30px;
  height: 34px;
  opacity: .5;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.input-edit-tenants > i:not(.tenants-clear-disabled):hover {
  opacity: .8;
}

.input-edit-tenants .tenants-clear-disabled {
  opacity: .2;
  cursor: default;
}

.input-edit-tenants > i::before {
  width: 18px;
  position: absolute;
  top: 9px;
  right: 10px;
  content: url('../../images/icons/Cross.svg');
}

.tenants-table {
  margin: 0 0 25px 0;
  display: grid;
  border-collapse: collapse;
  min-width: 740px;
  width: 100%;
  grid-template-columns:
    minmax(200px, 3fr)
    minmax(100px, 0.8fr)
    minmax(110px, 0.9fr)
    minmax(110px, 0.9fr)
    minmax(120px, 1fr)
    minmax(120px, 1fr)
    minmax(120px, 0.7fr)
    minmax(100px, 0.7fr);
  border: 1px solid #D6D6D6;
  border-radius: 4px;
}

.table-th-tenants {
  display: contents;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.table-th-tenants,
.table-body-tenants,
.tr-grid-tenants {
  display: contents;
}

.not-clickable-tenants button {
  margin: 0;
  padding: 0;
  border: none;
  background-color: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.not-clickable-tenants button:hover {
  color: #9D1B1F
}

.not-clickable-tenants button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.not-clickable-tenants button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px
}

.tenants-table-detailed .tr-grid-tenants td:last-of-type {
  padding-left: 0;
  border-left: 1px solid #E6E6E6;
}

.tr-grid-tenants td:last-of-type,
.tr-grid-tenants td:nth-of-type(3),
.tr-grid-tenants td:nth-of-type(4),
.tr-grid-tenants td:nth-of-type(5),
.tr-grid-tenants td:nth-of-type(6),
.tr-grid-tenants td:nth-of-type(7),
.tr-grid-tenants th:nth-of-type(3),
.tr-grid-tenants th:nth-of-type(4),
.tr-grid-tenants th:nth-of-type(5),
.tr-grid-tenants th:nth-of-type(6),
.tr-grid-tenants th:nth-of-type(7),
.tr-grid-tenants th:last-of-type {
  text-align: center;
  justify-content: center;
}

.tr-tenants {
  background-color: #F3F3F3;
  width: fit-content;
}

.tr-grid-tenants th {
  padding: 12px;
  text-align: left;
  background-color: #F3F3F3;
  border-bottom: 1px solid #E6E6E6;
  z-index: 3;
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.tr-grid-tenants td {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 3px 12px;
  color: #343232;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;

  border-bottom: 1px solid #E6E6E6;
}

.list-td-tenants {
  position: relative;
  color: #5D5B5B;
  transition: background-color .1s ease-in-out;
}

.list-td-tenants::before {
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.list-td-tenants-tenant-csv::before {
  content: url('../../images/icons/File\ Red.svg');
}
.list-td-tenants-tenant-google::before {
  content: url('../../images/icons/Google\ \(colors\).svg');
}
.list-td-tenants-tenant-azure::before {
  content: url('../../images/icons/Azure.svg');
}

.tenants-list-td-status-configured {
  display: flex;
  height: 24px;
  padding: 0px 10px 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: #2ED274;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.tenants-list-td-status-configured::before {
  position: relative;;
  content: url('../../images/icons/Check\ Circle\ Green\ little.svg');
  top: 1.5px;
}

.tenants-list-td-status-scheduled {
  display: flex;
  height: 24px;
  padding: 0px 10px 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: #5D5B5B;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.tenants-list-td-status-scheduled::before {
  position: relative;
  content: url('../../images/icons/icon\ _Clock_.svg');
  top: 1.5px;
}

.tenants-list-td-status-not-defined {
  color: #858484;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.list-td-tenants i {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #FFF;
  cursor: pointer;
  transition: fill .3s ease-in-out;
}

.list-td-tenants i:active {
  transform: translateY(-18px) scale(.9);
}

.list-td-tenants i::before {
  display: block;
  position: absolute;
  top: 11px;
  content: url('../../images/icons/clock-calendar.svg');
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.list-td-tenants i:hover::before {
  content: url('../../images/icons/clock-calendar-red.svg');
}

.all-tenant-button {
  padding: 8px 16px;
  margin: 3px 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.activate-tenant {
  color: #2ED274;
  border: 1px solid #2ED274;
  padding: 8px 23.35px;
}

.deactivate-tenant {
  color: #ED8F00;
  border: 1px solid #ED8F00;
}

.delete-tenant {
  color: #B72025;
  border: 1px solid #B72025;
}

.all-tenant-button.activate-tenant:hover {
  color: #FFFFFF;
  background-color: #2ED274;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
}

.all-tenant-button.deactivate-tenant:hover {
  color: #FFFFFF;
  background-color: #ED8F00;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
}

.all-tenant-button.delete-tenant:hover {
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
}

.frequency-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #5D5B5B;
  border-radius: 4px;
  cursor: pointer;
}

.frequency-btn:hover {
  border-color: #9D1B1F;
}

.frequency-btn:hover path {
  stroke: #9D1B1F !important;
}
